import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["multipleSelect"]

  connect() {
    this.isSubmitting = false
  }

  submit(event) {
    if (this.isSubmitting) return
    this.isSubmitting = true

    const form = event.target.form
    const formData = this.buildFormData(form, event.target)
    this.submitForm(form, formData)
  }

  submitMultipleSelect(event) {
    event.preventDefault()
    if (this.isSubmitting) return
    this.isSubmitting = true

    const form = event.target.closest('form')
    if (!form) return

    const formData = this.buildFormData(form)
    this.submitForm(form, formData)
  }

  buildFormData(form, changedInput = null) {
    const formData = new FormData(form)
    
    this.multipleSelectTargets.forEach(select => {
      if (!select.tomSelect) return
      
      const filterName = select.dataset.filterName
      const values = select.tomSelect.getValue()
      
      formData.delete(`filters[${filterName}][]`)
      if (values.length > 0) {
        values.forEach(value => {
          if (value) {
            formData.append(`filters[${filterName}][]`, value)
          }
        })
      } else {
        formData.append(`filters[${filterName}][]`, '')
      }
    })

    form.querySelectorAll('select:not([multiple]), input[type="checkbox"]').forEach(input => {
      const name = input.name
      if (input.type === 'checkbox') {
        formData.set(name, input.checked ? "1" : "0")
      } else if (input === changedInput && !input.value) {
        formData.set(name, "")
      }
    })

    return formData
  }

  buildUrlParams(formData) {
    const params = new URLSearchParams()
    
    for (const [key, value] of formData.entries()) {
      params.append(key, value)
    }
    
    this.multipleSelectTargets.forEach(select => {
      const filterName = select.dataset.filterName
      if (!params.has(`filters[${filterName}][]`)) {
        params.append(`filters[${filterName}][]`, '')
      }
    })

    return params
  }

  submitForm(form, formData) {
    const params = this.buildUrlParams(formData)
    window.location = `${form.action}?${params.toString()}`
  }
}
