import { Controller } from "@hotwired/stimulus";
import { fetchWithCsrf } from "../helpers";

// Connects to cartData-controller="cart"
export default class extends Controller {
  static values = {
    addToCartEndpoint: String, // Endpoint to add a product to the cart
    addToCartRedirectPath: String, // Path to redirect to after adding a product to the cart
    modifyCartEndpoint: String, // Endpoint to modify the cart
    removeFromCartEndpoint: String, // Endpoint to remove a product from the cart
    showCartEndpoint: String, // Endpoint to retrieve the cart from session
    emptyCartMessage: String, // Message to display when the cart is empty
  };

  static targets = [
    "cart", // Container that should display the full cart
    "totalQuantity", // Target to display the total quantity of products in the cart
  ];

  connect() {
    this.cartData = [];
    this.element.setAttribute("data-cart-initialized", true);
    this.addCartItemListeners();
  }

  disconnect() {
    this.removeCartItemListeners();
  }

  totalQuantityTargetConnected() {
    this.fetchCart().then((cartData) => {
      this.updateCartData(cartData);
    });
  }

  addCartItemListeners() {
    window.addEventListener("cart-item:remove", this.remove);
    window.addEventListener("cart-item:modify", this.modify);
  }

  removeCartItemListeners() {
    window.removeEventListener("cart-item:remove", this.remove);
    window.removeEventListener("cart-item:modify", this.modify);
  }

  cartTargetConnected() {
    this.addCartItemListeners();
  }

  cartTargetDisconnected() {
    this.removeCartItemListeners();
  }

  updateCartData(cartData) {
    this.cartData = cartData;
    const totalQuantity = this.cartData.reduce((acc, item) => acc + item.quantity, 0);
    this.totalQuantityTarget.textContent = totalQuantity;

    if (!this.hasCartTarget) {
      return;
    }

    this.updateCartSummary();
  }

  buildError(message) {
    const p = document.createElement("p");
    p.classList.add("error");
    p.textContent = message;
    return p;
  }

  async fetchCart() {
    const response = await fetchWithCsrf(this.showCartEndpointValue);
    return await response.json();
  }

  async addToCart(productId) {
    const response = await fetchWithCsrf(this.addToCartEndpointValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ product_id: productId }),
    });
    return await response.json();
  }

  async removeFromCart(productId) {
    const response = await fetchWithCsrf(this.removeFromCartEndpointValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ product_id: productId }),
    });
    return await response.json();
  }

  async modifyCart(params) {
    const { productId, quantity, priceId } = params;

    const response = await fetchWithCsrf(this.modifyCartEndpointValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_id: productId,
        quantity,
        price_id: priceId,
      }),
    });
    return await response.json();
  }

  async updateCartSummary() {
    const endpoint = this.cartTarget.dataset.summarizeCartPath;
    const response = await fetchWithCsrf(endpoint, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (!response.ok) {
      alert("Something went wrong, please try again later or contact support.");
    }

    return response.text().then((html) => Turbo.renderStreamMessage(html));
  }

  async updatePaymentsToday(event) {
    const paymentToday = event.currentTarget.value;
    const endpoint = this.cartTarget.dataset.updatePaymentsTodayCartPath;
    const response = await fetchWithCsrf(endpoint, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ payments_today: paymentToday }),
    });

    if (!response.ok) {
      alert("Something went wrong, please try again later or contact support.");
    }

    return response.text().then((html) => Turbo.renderStreamMessage(html));
  }

  redirectToCart() {
    window.location.href = this.addToCartRedirectPathValue;
  }

  add(event) {
    const { productId } = event.currentTarget.dataset;
    const redirectAfterAdd = event.currentTarget.dataset.redirectAfterAdd !== "false";

    this.addToCart(productId).then((cartData) => {
      this.updateCartData(cartData);

      if (redirectAfterAdd) {
        this.redirectToCart();
      }
    });
  }

  updateEmptyCartMessage() {
    if (!this.hasCartTarget || this.cartData.length !== 0) {
      return;
    }

    const p = document.createElement("p");
    p.textContent = this.emptyCartMessageValue;
    this.cartTarget.innerHTML = "";
    this.cartTarget.appendChild(p);
  }

  remove = (event) => {
    const { productId, gaItem } = event.detail;
    const { target } = event;
    target.classList.add("!hidden");

    this.removeFromCart(productId)
      .then((cartData) => {
        this.updateCartData(cartData);
        target.remove();
        this.updateEmptyCartMessage();
        rentOneGoogleAnalytics.removeFromCart({ item: gaItem });
      })
      .catch((error) => {
        target.classList.remove("!hidden");
        alert("Something went wrong while removing the product from the cart. Please try again.");
      });
  };

  modify = (event) => {
    const params = event.detail;

    this.modifyCart(params).then((cartData) => {
      this.updateCartData(cartData);
      this.cartTarget.setAttribute("data-cart-updated", true);
    });
  };
}
