import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categorySelect", "productCheckboxes", "hiddenProductIds"]

  connect() {
    this.initializeCheckboxes();
  }

  disconnect() {
    this.categorySelectTarget.removeEventListener('change', this.handleCategoryChange);
  }

  initializeCheckboxes() {
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.categorySelectTarget.addEventListener('change', this.handleCategoryChange);
  }

  handleCategoryChange() {
    const selectedOption = this.categorySelectTarget.options[this.categorySelectTarget.selectedIndex];
    const products = JSON.parse(selectedOption.dataset.products || '[]');

    this.productCheckboxesTarget.innerHTML = '';
    products.forEach(product => {
      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.name = 'category_product_ids[]';
      checkbox.value = product.id;
      checkbox.id = `product_${product.id}`;
      checkbox.checked = true;

      const label = document.createElement('label');
      label.htmlFor = `product_${product.id}`;
      label.textContent = product.name;

      checkbox.addEventListener('change', () => {
        const selectedProductIds = Array.from(this.productCheckboxesTarget.querySelectorAll('input[name="category_product_ids[]"]:checked')).map(cb => cb.value);
        this.hiddenProductIdsTarget.value = selectedProductIds.join(',');
      });

      const wrapper = document.createElement('div');
      wrapper.classList.add('product-checkbox');
      wrapper.appendChild(checkbox);
      wrapper.appendChild(label);
      this.productCheckboxesTarget.appendChild(wrapper);
    });

    const selectedProductIds = products.map(product => product.id);
    this.hiddenProductIdsTarget.value = selectedProductIds.join(',');
  }
}
