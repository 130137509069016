import { Controller } from "@hotwired/stimulus";
import TomSelectController from "./tom_select_controller";

export default class extends Controller {
  ANIMATE_CSS_CLASS_NAME = "animate__animated";

  static values = {
    closeOnFormSuccess: { type: Boolean, default: true }, // When set to true, the modal will close when the form is successfully submitted with turbo
    closeOnOutsideClick: { type: Boolean, default: true }, // When set to true, the modal will close when the user clicks outside the modal content
    animateInClass: { type: String, default: "" }, // The class to add to the modal content when it is animated in
    animateOutClass: { type: String, default: "" }, // The class to add to the modal content when it is animated out
    backdropAnimateInClass: { type: String, default: "animate__fadeIn" }, // The class to add to the backdrop when it is animated in
    triggeredElementId: { type: String, default: "" },
  };

  static targets = [
    "content", // The content of the modal, used to determine if click events should close the modal
  ];

  connect() {
    this.addEventListeners();

    if (this.animateInClassValue) {
      this.handleAnimateIn();
    }

    if (this.backdropAnimateInClassValue) {
      this.handleBackdropAnimateIn();
    }

    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.setProperty("--body-padding-right", `${scrollBarWidth}px`);
    document.body.classList.add("overflow-y-hidden");

    const focusableElements = this.getFocusableElements();
    if (focusableElements.length > 0) {
      focusableElements[0].focus();
    }
  }

  disconnect() {
    this.removeEventListeners();

    document.body.classList.remove("overflow-y-hidden");
    document.body.style.removeProperty("--body-padding-right");

    const triggeredElement = document.getElementById(this.triggeredElementIdValue);
    triggeredElement?.focus();
  }

  handleAnimateInEnd = () => {
    this.element.removeEventListener("animationend", this.handleAnimateInEnd);
    this.contentTarget.classList.remove(this.ANIMATE_CSS_CLASS_NAME);
    this.contentTarget.classList.remove(this.animateInClassValue);
  };

  handleAnimateIn() {
    this.element.addEventListener("animationend", this.handleAnimateInEnd);
    this.contentTarget.classList.add(this.ANIMATE_CSS_CLASS_NAME);
    this.contentTarget.classList.add(this.animateInClassValue);
  }

  handleAnimateOutEnd = () => {
    this.element.removeEventListener("animationend", this.handleAnimateOutEnd);
    this.contentTarget.classList.remove(this.ANIMATE_CSS_CLASS_NAME);
    this.contentTarget.classList.remove(this.animateOutClassValue);
    this.closeModal();
  };

  handleAnimateOut() {
    this.element.addEventListener("animationend", this.handleAnimateOutEnd);
    this.contentTarget.classList.add(this.ANIMATE_CSS_CLASS_NAME);
    this.contentTarget.classList.add(this.animateOutClassValue);
  }

  handleBackdropAnimateInEnd = () => {
    this.element.removeEventListener("animationend", this.handleBackdropAnimateInEnd);
    this.element.classList.remove(this.ANIMATE_CSS_CLASS_NAME);
    this.element.classList.remove(this.backdropAnimateInClassValue);
  };

  handleBackdropAnimateIn() {
    this.element.addEventListener("animationend", this.handleBackdropAnimateInEnd);
    this.element.classList.add(this.ANIMATE_CSS_CLASS_NAME);
    this.element.classList.add(this.backdropAnimateInClassValue);
  }

  addEventListeners() {
    window.addEventListener("keyup", this.onKeyup);

    if (this.closeOnOutsideClickValue) {
      window.addEventListener("click", this.onClickOutside);
    }

    if (this.closeOnFormSuccessValue) {
      this.element.addEventListener("turbo:submit-end", this.onSubmitEnd);
    }

    this.element.addEventListener("keydown", this.handleTabKey);
  }

  removeEventListeners() {
    window.removeEventListener("keyup", this.onKeyup);

    if (this.closeOnOutsideClickValue) {
      window.removeEventListener("click", this.onClickOutside);
    }

    if (this.closeOnFormSuccessValue) {
      this.element.removeEventListener("turbo:submit-end", this.onSubmitEnd);
    }

    this.element.removeEventListener("keydown", this.handleTabKey);
  }

  onKeyup = (event) => {
    if (event.key !== "Escape") {
      return;
    }

    this.cancel();
  };

  onClickOutside = (event) => {
    if (!this.hasContentTarget || this.contentTarget.contains(event.target)) {
      return;
    }

    this.cancel();
  };

  onSubmitEnd = (event) => {
    const { success } = event.detail;

    if (!success) {
      return;
    }

    this.complete();
  };

  cancel() {
    this.close(new CustomEvent("modal:cancel"));
  }

  complete() {
    this.close(new CustomEvent("modal:complete"));
  }

  closeModal() {
    const scrollPosition = window.scrollY;
    
    this.element.parentElement.src = "";
    this.element.remove();

    requestAnimationFrame(() => {
      window.scrollTo(0, scrollPosition);
    });
  }

  close(event) {
    window.dispatchEvent(event);

    if (this.animateOutClassValue) {
      this.handleAnimateOut();
      return;
    }

    this.closeModal();
  }

  handleTabKey = (event) => {
    if (event.key !== "Tab") return;

    const focusableElements = this.getFocusableElements();
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    if (event.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        event.preventDefault();
        lastFocusableElement.focus();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    }
  };

  getFocusableElements() {
    return Array.from(
      this.element.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
    ).filter((el) => !el.hasAttribute("disabled") && el.getAttribute("tabindex") !== "-1");
  }
}
