import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="click-navigation"
// Allows for turbo navigation to a specified path when clicking the controlled element
// Ignores clicks on anchor, button, input, and textarea elements
export default class extends Controller {
  static values = {
    path: String,
  };

  connect() {
    this.element.addEventListener("click", this.handleClick);
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleClick);
  }

  handleClick = (event) => {
    const clickedElement = event.target;
    const ignoredElements = ["A", "BUTTON", "INPUT", "TEXTAREA"];

    if (ignoredElements.includes(clickedElement.tagName)) {
      return;
    }

    Turbo.visit(this.pathValue);
  };
}
