import { Controller } from "@hotwired/stimulus";

/**
 * Fades an element out (by adding the `fade-out` class) and then hides it (by adding the `!hidden` class).
 */
export default class FadeOutController extends Controller {
  static values = {
    /**
     * If set, automatically fades the element out after the specified number of milliseconds.
     */
    automaticTimeout: Number,

    /**
     * Fade out class name.
     */
    fadeOutClass: { type: String, default: "fade-out" },

    /**
     * If true, the element is removed from the DOM after the fade-out effect is complete.
     * If false, the element is hidden but remains in the DOM.
     */
    removeAfterFadeOut: { type: Boolean, default: false },

    /**
     * Duration of the fade-out effect in milliseconds. Element is hidden after the duration.
     */
    duration: Number,
  };

  connect() {
    this.controlledTarget = this.element;

    if (this.automaticTimeoutValue) {
      this.timeoutId = setTimeout(() => {
        this.fadeOut(new CustomEvent("programmatic"));
      }, this.automaticTimeoutValue);
    }
  }

  disconnect() {
    if (this.removeAfterFadeOutValue) {
      this.controlledTarget.removeEventListener("animationend", this.onFadeOutEnd);
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  onFadeOutEnd = () => {
    this.controlledTarget.remove();
  };

  fadeOut(event) {
    if (this.removeAfterFadeOutValue) {
      this.controlledTarget.addEventListener("animationend", this.onFadeOutEnd);
    }

    this.controlledTarget.classList.add(this.fadeOutClassValue);

    setTimeout(() => {
      this.controlledTarget.classList.add("!hidden");
    }, this.durationValue);

    if (event && event.type === "click") {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
