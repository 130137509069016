import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.debouncedSearchFunction = debounce(() => {
      if (this.inputTarget.value.length === 0 || this.inputTarget.value.length >= 3) {
        this.element.requestSubmit();
      }
    }, 300);

    this.focusInput();
  }

  disconnect() {
    if (this.debouncedSearchFunction) {
      this.debouncedSearchFunction.cancel();
    }
  }

  focusInput() {
    this.inputTarget.focus();
    const length = this.inputTarget.value.length;
    this.inputTarget.setSelectionRange(length, length);
  }

  debouncedSearch(event) {
    this.debouncedSearchFunction();
  }

  clear(event) {
    event.preventDefault()
    this.inputTarget.value = "";
    this.element.requestSubmit();
  }
}
