import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["input", "suggestions"];

  initialize() {
    this.timeout = null;
  }

  connect() {
    this.inputTarget.addEventListener("keydown", this.onKeyDown.bind(this));
    this.inputTarget.addEventListener("search", this.clearAutocomplete.bind(this));
  }

  disconnect() {
    this.inputTarget.removeEventListener("keydown", this.onKeyDown.bind(this));
    this.inputTarget.removeEventListener("search", this.clearAutocomplete.bind(this));
  }

  search(event) {
    const searchInput = this.inputTarget.value.trim();

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.autocomplete(searchInput);
    }, 300);
  }

  onKeyDown(event) {
    const searchInput = this.inputTarget.value.trim();

    if (event.key === "Enter" && searchInput) {
      event.preventDefault();

      if (!this.handleJobSearch(event)) {
        window.location.href = `/search?search=${encodeURIComponent(searchInput)}`;
      }
    }
  }

  autocomplete(searchInput) {
    if (searchInput.length < 2) {
      this.clearAutocomplete();
      return;
    }

    fetch(`/search/autocomplete?search=${encodeURIComponent(searchInput)}`)
      .then((response) => response.json())
      .then((data) => {
        this.clearAutocomplete();
        const suggestions = data;

        if (suggestions.length === 0) {
          return;
        }

        this.suggestionsTarget.classList.add("active");
        this.suggestionsTarget.innerHTML = `
          <ul>
            ${suggestions
              .map((match) => `<li><a class="suggestion" href="${match.url}">${match.label}</a></li>`)
              .join("")}
          </ul>
        `;
      });
  }

  handleJobSearch(event) {
    const searchInput = event.target.value;
    const jobRegex = /jobs?|careers?/i;
    const isJobSearch = jobRegex.test(searchInput);

    if (isJobSearch) {
      window.location.href = "https://jobs.shoprentone.com/";
    }

    return isJobSearch;
  }

  clearAutocomplete() {
    this.suggestionsTarget.classList.remove("active");
    this.suggestionsTarget.innerHTML = "";
  }
}
