export function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element?.getAttribute("content");
}

export function fetchWithCsrf(url, options = {}) {
  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      "X-CSRF-Token": getMetaValue("csrf-token"),
    },
  });
}
