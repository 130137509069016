import { Controller } from "@hotwired/stimulus";

/**
 * Manages the <code>aria-expanded</code> attribute of an element and toggles an <code>expanded</code> class
 * on its controlled element.
 */
export default class AriaExpandedController extends Controller {
  static values = {
    /**
     * If true, collapses the controlled element when a click event or escape key is detected outside of the controlled element.
     * This is useful for dropdown menus and other elements that should collapse when the user clicks outside of them.
     */
    collapseOnDismiss: Boolean,

    /**
     * Remembers the toggle state in local storage.
     * This is useful when a menu state should be consistent across page visits.
     * Note that this controller does not use this saved state.
     * It is up to you to set the initial state of the controlled element.
     */
    remember: Boolean
  };

  connect() {
    this.controllerTarget = this.element;

    let ariaControlsId = this.controllerTarget.getAttribute('aria-controls');
    if (!ariaControlsId) {
      throw new Error('The aria-expanded controller requires an aria-controls attribute.');
    }

    this.controlledTarget = document.getElementById(ariaControlsId);
    if (!this.controlledTarget) {
      throw new Error(`The aria-expanded controller could not find an element with id[${ariaControlsId}].`);
    }

    // if no `aria-expanded` attribute is set, set it to false.
    if (!this.controllerTarget.hasAttribute('aria-expanded')) {
      this.controllerTarget.setAttribute('aria-expanded', 'false');
    }

    // if no `data-action` attribute is set, set one to toggle the expanded state of the controllerTarget.
    if (!this.controllerTarget.hasAttribute('data-action')) {
      this.controllerTarget.setAttribute('data-action', 'aria--expanded#toggle');
    }

    if (this.collapseOnDismissValue) {
      this.boundDismiss = this.dismiss.bind(this);
      document.addEventListener('click', this.boundDismiss);
      document.addEventListener('keydown', this.boundDismiss);
    }
  }

  disconnect() {
    if (this.collapseOnDismissValue) {
      document.removeEventListener('click', this.boundDismiss);
      document.removeEventListener('keydown', this.boundDismiss);
    }
  }

  /**
   * Collapses the controlled element by removing the `expanded` class from its class list.
   * Also sets the `aria-expanded` attribute to false on the controller element.
   * Optionally remembers the expanded state in local storage.
   */
  collapse() {
    this.controlledTarget.classList.remove('expanded');
    this.controllerTarget.setAttribute('aria-expanded', false);
    this.rememberExpandedState();
  }

  /**
   * Collapses the controlled element when a click event or escape key is detected outside of the controlled element.
   * @param event the input event that might trigger the collapse.
   */
  dismiss(event) {
    const closestControllerTarget = event.target.closest('[data-controller="aria--expanded"]');
    if (closestControllerTarget === this.controllerTarget) return; // if the clicked element is a controller, do nothing.

    if (event.type === 'click' && !this.controlledTarget.contains(event.target)) {
      this.collapse();
    } else if (event.type === 'keydown' && event.key === 'Escape') {
      this.collapse();
    }
  }

  /**
   * Expands the controlled element by adding the `expanded` class to its class list.
   * Also sets the `aria-expanded` attribute to true on the controller element.
   * Optionally remembers the expanded state in local storage.
   */
  expand() {
    this.controlledTarget.classList.add('expanded');
    this.controllerTarget.setAttribute('aria-expanded', true);
    this.rememberExpandedState();
  }

  /**
   * Remembers the expanded state in local storage.
   * This is useful when a menu state should be consistent across page visits.
   * However, this controller does not make use of this saved state.
   * You must set the initial state of the controlled element yourself in the view HTML.
   */
  rememberExpandedState() {
    if (this.rememberValue) {
      localStorage.setItem('aria_expanded_for_' + this.controlledTarget.id, this.controllerTarget.getAttribute('aria-expanded'));
    }
  }

  /**
   * Toggles the expanded state of the controlled element.
   * @param event
   */
  toggle(event) {
    // debounce to avoid repeat toggles (e.g., from mobile taps or double-clicks).
    if (this.isToggling) {
      return;
    }
    this.isToggling = true;

    let isExpanded = this.controllerTarget.getAttribute('aria-expanded') === 'true';
    isExpanded ? this.collapse() : this.expand();

    setTimeout(() => {
      this.isToggling = false;
    }, 100);
  }
}
