import {Controller} from "@hotwired/stimulus"
import Pagy from "../pagy.mjs";
import { fetchWithCsrf } from "../helpers"

export default class PagyController extends Controller {
    connect() {
        Pagy.init(this.element)
        this.setupLimitChangeHandler()
    }

    disconnect() {
        if (this.limitSelector && this.handleLimitChange) {
            this.limitSelector.removeEventListener('change', this.handleLimitChange)
        }
    }

    setupLimitChangeHandler() {
        this.limitSelector = this.element.querySelector('.pagy-items-selector input')
        if (!this.limitSelector) return

        this.handleLimitChange = (event) => {
            const perPage = event.target.value
            
            // Update preference via AJAX
            fetchWithCsrf('/admin/column_preferences', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    columns: {
                        per_page: parseInt(perPage)
                    }
                })
            })
        }

        this.limitSelector.addEventListener('change', this.handleLimitChange)
    }
}
