import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["searchArea", "resultsContainer", "searchForm", "useCurrentLocation", "useCurrentLocationText", "findingLocationText", "latitude", "longitude"];
  currentIndex = -1;

  connect() {
    this.handleInput = debounce(this.handleInput, 300);
    this.searchAreaTarget.addEventListener('input', this.handleInput);
    this.searchAreaTarget.addEventListener('keydown', this.handleKeydown);
  }

  disconnect() {
    this.searchAreaTarget.removeEventListener('input', this.handleInput);
    this.searchAreaTarget.removeEventListener('keydown', this.handleKeydown);
  }

  handleInput = (event) => {
    const query = this.searchAreaTarget.value;
    this.currentIndex = -1;

    if (query.length > 2) {
      fetch(`/locations/autocomplete?query=${query}`)
        .then(response => response.json())
        .then(data => {
          this.resultsContainerTarget.innerHTML = '';
          data.forEach((item, index) => {
            const locationText = `${item[0]} (${item[1]})`;
            const li = document.createElement('li');
            li.textContent = locationText;
            li.classList.add('px-4', 'py-2', 'cursor-pointer', 'hover:bg-red-500', 'hover:text-white');
            li.dataset.index = index;
            li.addEventListener('click', () => {
              this.searchAreaTarget.value = locationText;
              this.resultsContainerTarget.innerHTML = '';
              this.searchFormTarget.submit();
            });
            this.resultsContainerTarget.appendChild(li);
          });
        });
    } else {
      this.resultsContainerTarget.innerHTML = '';
    }
  }

  handleKeydown = (event) => {
    const items = this.resultsContainerTarget.querySelectorAll('li');
    if (items.length === 0) return;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this.currentIndex = (this.currentIndex + 1) % items.length;
        this.updateHighlight(items);
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.currentIndex = (this.currentIndex - 1 + items.length) % items.length;
        this.updateHighlight(items);
        break;
      case 'Enter':
        if (this.currentIndex >= 0) {
          event.preventDefault();
          items[this.currentIndex].click();
        }
        break;
    }
  }

  updateHighlight(items) {
    items.forEach((item, index) => {
      if (index === this.currentIndex) {
        item.classList.add('bg-red-500', 'text-white');
      } else {
        item.classList.remove('bg-red-500', 'text-white');
      }
    });
  }

  handleUseCurrentLocation = (event) => {
    event.preventDefault();
    this.useCurrentLocationTextTarget.style.display = 'none';
    this.findingLocationTextTarget.style.display = 'inline';
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setPosition, this.showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  setPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    this.latitudeTarget.value = latitude;
    this.longitudeTarget.value = longitude;
    this.searchFormTarget.submit();
  }

  showError = (error) => {
    switch(error.code) {
      case error.PERMISSION_DENIED:
        alert("You must accept location permissions to use current location feature.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
    }
  }
}
