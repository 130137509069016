import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

const DESKTOP_BREAKPOINT = 768;

export default class extends Controller {
  static targets = ["trigger", "panel", "panelContainer"];

  connect() {
    this.setInitialState();
    this.updateLayout();
    this.boundUpdateLayout = throttle(() => this.updateLayout(), 200);
    window.addEventListener("resize", this.boundUpdateLayout);
  }

  disconnect() {
    window.removeEventListener("resize", this.boundUpdateLayout);
  }

  toggle = (event) => {
    const trigger = event.currentTarget;
    const panelId = trigger.getAttribute("aria-controls");
    const panel = this.panelTargets.find((p) => p.id === panelId);

    if (window.innerWidth >= DESKTOP_BREAKPOINT) {
      this.handleTabClick(trigger, panel);
      return;
    }

    this.handleAccordionClick(trigger, panel);
  };

  handleTabClick = async (trigger, panel) => {
    await this.fadeOutCurrentPanel();
    this.deactivateAllTriggers();
    this.activateTrigger(trigger);
    this.showPanel(panel);
  };

  fadeOutCurrentPanel = async () => {
    const visiblePanel = this.panelTargets.find((p) => !p.hidden);
    if (!visiblePanel) {
      return;
    }

    visiblePanel.classList.remove("opacity-100");
    await new Promise((resolve) => setTimeout(resolve, 200));
    visiblePanel.hidden = true;
  };

  deactivateAllTriggers = () => {
    this.triggerTargets.forEach((t) => {
      t.setAttribute("aria-selected", "false");
      t.setAttribute("aria-expanded", "false");
    });
  };

  activateTrigger = (trigger) => {
    trigger.setAttribute("aria-selected", "true");
    trigger.setAttribute("aria-expanded", "true");
  };

  showPanel = (panel) => {
    panel.hidden = false;
    panel.offsetHeight; // Force reflow for animation
    panel.classList.add("opacity-100");
  };

  handleAccordionClick = async (trigger, panel) => {
    const isExpanded = trigger.getAttribute("aria-expanded") === "true";
    trigger.setAttribute("aria-expanded", !isExpanded ? "true" : "false");

    if (isExpanded) {
      await this.closeAccordionPanel(panel);
      return;
    }

    await this.openAccordionPanel(panel);
  };

  closeAccordionPanel = async (panel) => {
    if (!panel) {
      return;
    }

    panel.style.maxHeight = "0px";
    await new Promise((resolve) => setTimeout(resolve, 200));
    panel.hidden = true;
  };

  openAccordionPanel = async (panel) => {
    if (!panel) {
      return;
    }

    panel.hidden = false;
    panel.offsetHeight; // Force reflow for animation
    panel.style.maxHeight = `${panel.scrollHeight}px`;
  };

  updateLayout = () => {
    const isDesktop = window.innerWidth >= DESKTOP_BREAKPOINT;
    const wasDesktop = !this.panelTargets[0].parentElement.classList.contains("disclosure-triggers");

    this.movePanels(isDesktop);

    if (wasDesktop && !isDesktop) {
      this.handleDesktopToMobileTransition();
    } else if (!wasDesktop && isDesktop) {
      this.handleMobileToDesktopTransition();
    }

    if (!isDesktop) {
      this.updateExpandedPanelHeights();
      return;
    }

    this.ensureTabSelected();
  };

  movePanels = (isDesktop) => {
    this.panelTargets.forEach((panel, index) => {
      const trigger = this.triggerTargets[index];
      if (isDesktop) {
        this.panelContainerTarget.appendChild(panel);
      } else {
        trigger.insertAdjacentElement("afterend", panel);
      }
    });
  };

  handleDesktopToMobileTransition = () => {
    const selectedTrigger = this.triggerTargets.find((t) => t.getAttribute("aria-selected") === "true");
    this.resetPanelsForMobile();
    this.expandSelectedPanel(selectedTrigger);
    this.updateTriggerStates(selectedTrigger);
  };

  resetPanelsForMobile = () => {
    this.panelTargets.forEach((p) => {
      p.style.maxHeight = "0px";
      p.hidden = false;
      p.classList.remove("opacity-0", "opacity-100");
    });
  };

  expandSelectedPanel = (selectedTrigger) => {
    if (!selectedTrigger) {
      return;
    }

    const selectedPanel = this.panelTargets.find((p) => p.id === selectedTrigger.getAttribute("aria-controls"));
    if (!selectedPanel) {
      return;
    }

    selectedPanel.style.maxHeight = `${selectedPanel.scrollHeight}px`;
  };

  updateTriggerStates = (selectedTrigger) => {
    this.triggerTargets.forEach((t) => {
      t.setAttribute("aria-expanded", t === selectedTrigger ? "true" : "false");
    });
  };

  handleMobileToDesktopTransition = () => {
    const lastExpandedTrigger = [...this.triggerTargets]
      .reverse()
      .find((t) => t.getAttribute("aria-expanded") === "true");
    const selectedTrigger = lastExpandedTrigger || this.triggerTargets[0];

    this.updatePanelsForDesktop(selectedTrigger);
    this.updateTriggersForDesktop(selectedTrigger);
  };

  updatePanelsForDesktop = (selectedTrigger) => {
    if (!selectedTrigger) {
      return;
    }

    this.panelTargets.forEach((p) => {
      p.style.maxHeight = "";
      const isSelected = p.id === selectedTrigger.getAttribute("aria-controls");
      p.hidden = !isSelected;
      p.classList.toggle("opacity-100", isSelected);
    });
  };

  updateTriggersForDesktop = (selectedTrigger) => {
    if (!selectedTrigger) {
      return;
    }

    this.triggerTargets.forEach((t) => {
      const isSelected = t === selectedTrigger;
      t.setAttribute("aria-selected", isSelected ? "true" : "false");
      t.setAttribute("aria-expanded", isSelected ? "true" : "false");
    });
  };

  updateExpandedPanelHeights = () => {
    this.triggerTargets.forEach((trigger) => {
      if (trigger.getAttribute("aria-expanded") !== "true") {
        return;
      }

      const panel = this.panelTargets.find((p) => p.id === trigger.getAttribute("aria-controls"));
      if (!panel || panel.hidden) {
        return;
      }

      panel.style.maxHeight = `${panel.scrollHeight}px`;
    });
  };

  ensureTabSelected = () => {
    if (this.triggerTargets.some((t) => t.getAttribute("aria-selected") === "true")) {
      return;
    }

    this.triggerTargets[0].setAttribute("aria-selected", "true");
    this.triggerTargets[0].setAttribute("aria-expanded", "true");
    this.panelTargets[0].hidden = false;
    this.panelTargets[0].classList.add("opacity-100");
  };

  setInitialState = () => {
    const isDesktop = window.innerWidth >= DESKTOP_BREAKPOINT;
    isDesktop ? this.setDesktopInitialState() : this.setMobileInitialState();
  };

  setDesktopInitialState = () => {
    this.activateTrigger(this.triggerTargets[0]);
    this.showPanel(this.panelTargets[0]);

    this.panelTargets.slice(1).forEach((p) => {
      p.hidden = true;
      p.classList.remove("opacity-100");
    });
  };

  setMobileInitialState = () => {
    this.deactivateAllTriggers();
    this.panelTargets.forEach((p) => {
      p.style.maxHeight = "0px";
      p.hidden = false;
      p.classList.remove("opacity-0", "opacity-100");
    });
  };
}
