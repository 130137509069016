import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["type", "applyTo", "whichItems", "value", "valueHint", "feeIds", "conditionalPromoIds", "conditionalPromoId", "status"];

  connect() {
    const typeValue = this.typeTarget.value;
    const applyToInput = this.applyToTarget.querySelector('select');
    const applyToValue = applyToInput ? applyToInput.value : null;

    this.updateVisibility(typeValue);
    this.updateHintVisibility(applyToValue);
    this.toggleType();
    this.updateTypeOptions();

    this.statusTargets.forEach(radio => {
      radio.addEventListener('change', this.updateTypeOptions);
    });
  }

  disconnect() {
    this.statusTargets.forEach(radio => {
      radio.removeEventListener('change', this.updateTypeOptions);
    });
  }

  toggle = (ev) => {
    const typeValue = ev.target.value;
    this.updateVisibility(typeValue);
    this.toggleType();
  }

  toggleApplyTo = (ev) => {
    const applyToValue = ev.target.value;
    this.updateHintVisibility(applyToValue);
  }

  updateVisibility = (typeValue) => {
    const applyToVisible = !["freeAtCheckout", "bogo", "paymentMatch"].includes(typeValue);
    const whichItemsVisible = !["paymentMatch", "bogo"].includes(typeValue);
    const valueVisible = !["freeAtCheckout", "bogo", "paymentMatch"].includes(typeValue);
    const feeIdsVisible = typeValue !== "bogo";
    const conditionalPromoIdsVisible = typeValue === "bogo";

    this.applyToTarget.classList.toggle('hidden', !applyToVisible);
    this.whichItemsTarget.classList.toggle('hidden', !whichItemsVisible);
    this.valueTarget.classList.toggle('hidden', !valueVisible);
    this.feeIdsTarget.classList.toggle('hidden', !feeIdsVisible);
    this.conditionalPromoIdsTarget.classList.toggle('hidden', !conditionalPromoIdsVisible);
  }

  updateHintVisibility = (applyToValue) => {
    const hintVisible = applyToValue === 'term';
    this.valueHintTarget.classList.toggle('hidden', !hintVisible);
  }

  toggleType() {
    const type = this.typeTarget.value;
    if (type === 'bogo') {
      this.conditionalPromoIdTarget.setAttribute('required', 'required');
    } else {
      this.conditionalPromoIdTarget.removeAttribute('required');
    }
  }

  updateTypeOptions=() => {
    const statusValue = this.statusTargets.find(radio => radio.checked)?.value;
    const isConditional = statusValue === 'conditional';
    const bogoOption = Array.from(this.typeTarget.options).find(option => option.value === 'bogo');

    if (isConditional && bogoOption) {
      bogoOption.remove();
    } else if (!isConditional && !bogoOption) {
      const newOption = document.createElement('option');
      newOption.value = 'bogo';
      newOption.text = 'Bogo';
      this.typeTarget.add(newOption);
    }

    if (this.stackableTarget) {
      this.stackableTarget.classList.toggle('hidden', isConditional);
    }
  }
}
