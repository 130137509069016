import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="multi-step"
export default class extends Controller {
  static values = {
    activeClass: { type: String, default: "active" },
    mobileOnly: Boolean, // When set to true, all steps are shown on desktop
    selectedStep: String, // ID of the step to show on page load, useful for testing individual steps
  };

  static targets = ["step", "progress"];

  connect() {
    if (this.stepTargets.length === 0) {
      console.error("No steps found for multi-step controller");
      return;
    }

    if (this.hasSelectedStepValue && this.selectedStepValue !== "") {
      this.showStep(this.selectedStepValue);
      return;
    }

    this.showFirstStep();
  }

  showFirstStep() {
    this.stepTargets[0].classList.add(this.activeClassValue);
    this.updateProgress();
  }

  currentStepIndex() {
    return this.stepTargets.findIndex((step) =>
      step.classList.contains(this.activeClassValue)
    );
  }

  show(event) {
    const { currentTarget } = event;
    const ariaTarget = currentTarget.getAttribute("aria-controls");

    this.stepTargets.forEach((step) => {
        const isTarget = step.getAttribute("id") === ariaTarget;
        step.classList.toggle(this.activeClassValue, isTarget);

        if (isTarget) {
            document.getElementById('currentStep').value = ariaTarget;
        }
    });

    this.updateProgress();
  }

  updateProgress() {
    if (!this.hasProgressTarget) {
      return;
    }

    const stepIndex = this.currentStepIndex();

    this.progressTargets.forEach((progressTarget, index) => {
      const active = index === stepIndex;
      progressTarget.classList.toggle(this.activeClassValue, active);

      const complete = index < stepIndex;
      progressTarget.classList.toggle("complete", complete);
    });
  }

  showStep(stepId) {
    const selectedStep = this.stepTargets.find((step) => {
      return step.getAttribute("id") === stepId;
    });

    if (!selectedStep) {
      console.debug(`No step found with ID ${stepId}`);
      return;
    }

    this.stepTargets.forEach((step) => {
      const isTarget = step === selectedStep;
      step.classList.toggle(this.activeClassValue, isTarget);
    });

    this.updateProgress();
  }
}
