import { Controller } from "@hotwired/stimulus";

import tinymce from "tinymce/tinymce";
import "tinymce/models/dom/model";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver/theme";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/preview";
import "tinymce/plugins/table";
import "tinymce/plugins/wordcount";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    this.defaults = {
      autoresize_bottom_margin: 10,
      browser_spellcheck: true,
      content_css: false,
      content_style: `
        html {
          font-family: Roboto, sans-serif; line-height: 1.5;
        }
        h3, h4 {
          font-family: 'Open Sans', sans-serif;
          line-height: 1;
          margin: .9rem 0;
          font-weight: bold;
          text-transform: uppercase;
        }
        .center {
          text-align: center;
        }
        `,
      invalid_elements: "span",
      link_context_toolbar: true,
      link_default_target: "_blank",
      link_title: false,
      max_height: 700,
      menubar: false,
      mobile: {
        toolbar: [
          "styles | bold italic underline strikethrough superscript",
          "blockquote numlist bullist link | alignleft aligncenter alignright | table",
          "undo redo | fullscreen preview code",
        ],
      },
      plugins: "link lists fullscreen preview table code autoresize wordcount",
      relative_urls: false,
      skin: false,
      style_formats: [
        { title: "Heading", format: "h3" },
        { title: "Sub Heading", format: "h4" },
        { title: "Sub Heading 2", format: "h5" },
        { title: "Sub Heading 3", format: "h6" },
        { title: "Paragraph", format: "p" },
      ],
      toolbar: [
        "styles | bold italic underline strikethrough superscript | blockquote numlist bullist link | alignleft aligncenter alignright | table",
        "undo redo | fullscreen preview code help",
      ],
      valid_styles: { "*": "text-align" },
      contextmenu: false,
      license_key: 'gpl',
    };
  }

  connect() {
    this.editors = [];

    let config = Object.assign({ target: this.inputTarget }, this.defaults);
    tinymce.init(config).then((editors) => {
      this.editors = editors;
    });
  }

  disconnect() {
    this.editors.forEach((editor) => {
      tinymce.remove(editor);
    });
  }
}
