import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form"
export default class extends Controller {
  static values = {
    disableUntilValid: { type: Boolean, default: true }, // When set to true, the submit button will be disabled until the form is valid
    disableUntilChanged: { type: Boolean, default: false }, // When set to true, the submit button will be disabled until the form is changed
    redirectOnSuccessUrl: { type: String, default: null }, // When set, the form will be redirected to the given URL after the form is submitted
    openRedirectInNewTab: { type: Boolean, default: false }, // When set to true, the form will be redirected to the given URL in a new tab after the form is submitted
  };

  static targets = [
    "requiredFileInput", // An input[type=file] that is required
    "requiredCheckboxGroup", // An element containing a group of checkboxes that requires selection
    "requiredRadioGroup", // An element containing a group of radio buttons that requires selection
    "requiredTextInput", // A textarea or input[type=text] that is required
    "submit", // The submit button that gets enabled/disabled and receives a loading state
  ];

  connect() {
    this.storeInitialValues();
    this.validateForm();
    this.addEventListeners();
  }

  disconnect() {
    const form = this.element;

    if (!this.isTurboForm()) {
      form.removeEventListener("submit", this.onSubmitStart);
      return;
    }

    form.removeEventListener("turbo:submit-start", this.onSubmitStart);
    form.removeEventListener("turbo:submit-end", this.onSubmitEnd);
    form.removeEventListener("change", this.onChange);

    this.requiredTextInputTargets.forEach((input) => {
      input.removeEventListener("input", this.onChange);
    });
  }

  isTurboForm() {
    return this.element.getAttribute("data-turbo") !== "false";
  }

  storeInitialValues() {
    this.initialValues = {};

    if (!this.disableUntilChangedValue) {
      return;
    }

    this.requiredTextInputTargets.forEach((input) => {
      this.initialValues[input.name] = input.value;
    });

    this.requiredFileInputTargets.forEach((input) => {
      this.initialValues[input.name] = input.value;
    });

    this.requiredCheckboxGroupTargets.forEach((group) => {
      const checkboxes = group.querySelectorAll("input[type=checkbox]");

      checkboxes.forEach((checkbox) => {
        this.initialValues[checkbox.name] = checkbox.checked;
      });
    });

    this.requiredRadioGroupTargets.forEach((group) => {
      const radios = group.querySelectorAll("input[type=radio]");

      radios.forEach((radio) => {
        this.initialValues[radio.name] = radio.checked;
      });
    });
  }

  hasFormChanged() {
    if (!this.disableUntilChangedValue) {
      return;
    }

    let changed = false;

    this.requiredTextInputTargets.forEach((input) => {
      if (input.value.trim() !== this.initialValues[input.name]) {
        changed = true;
      }
    });

    this.requiredFileInputTargets.forEach((input) => {
      if (input.value !== this.initialValues[input.name]) {
        changed = true;
      }
    });

    this.requiredCheckboxGroupTargets.forEach((group) => {
      const checkboxes = group.querySelectorAll("input[type=checkbox]");

      checkboxes.forEach((checkbox) => {
        if (checkbox.checked !== this.initialValues[checkbox.name]) {
          changed = true;
        }
      });
    });

    this.requiredRadioGroupTargets.forEach((group) => {
      const radios = group.querySelectorAll("input[type=radio]");

      radios.forEach((radio) => {
        if (radio.checked !== this.initialValues[radio.name]) {
          changed = true;
        }
      });
    });

    return changed;
  }

  addEventListeners() {
    const form = this.element;

    if (!this.isTurboForm()) {
      form.addEventListener("submit", this.onSubmitStart);
      return;
    }

    form.addEventListener("turbo:submit-start", this.onSubmitStart);
    form.addEventListener("turbo:submit-end", this.onSubmitEnd);
    form.addEventListener("change", this.onChange);

    this.requiredTextInputTargets.forEach((input) => {
      input.addEventListener("input", this.onChange);
    });
  }

  onSubmitStart = (event) => {
    this.submitTarget.disabled = true;
    this.setLoadingState();
  };

  onSubmitEnd = (event) => {
    this.submitTarget.disabled = false;
    this.clearLoadingState();

    if (event.detail.success && this.redirectOnSuccessUrlValue) {
      this.handleRedirect();
    }
  };

  handleRedirect() {
    if (this.openRedirectInNewTabValue) {
      window.open(this.redirectOnSuccessUrlValue, "_blank");
      return;
    }

    window.location.href = this.redirectOnSuccessUrlValue;
  }

  setLoadingState() {
    this.initialSubmitButtonWidth = this.submitTarget.offsetWidth;
    this.submitTarget.style.minWidth = `${this.initialSubmitButtonWidth}px`;
    this.submitTarget.classList.add("loading");
  }

  clearLoadingState() {
    this.submitTarget.classList.remove("loading");
    this.submitTarget.style.minWidth = "";
  }

  onChange = (event) => {
    this.validateForm();
  };

  isRadioGroupValid(element) {
    const radios = element.querySelectorAll("input[type=radio]");

    return Array.from(radios).some((radio) => {
      return radio.checked;
    });
  }

  isCheckboxGroupValid(element) {
    const checkboxes = element.querySelectorAll("input[type=checkbox]");
    const checkboxesArray = Array.from(checkboxes);
    const min = element.dataset.min || 1;
    const max = element.dataset.max || checkboxesArray.length;

    const checkedCount = checkboxesArray.filter((checkbox) => {
      return checkbox.checked;
    }).length;

    return checkedCount >= min && checkedCount <= max;
  }

  isTextInputValid(element) {
    return element.value.trim() !== "";
  }

  isValidFileInput(element) {
    return element.files.length > 0;
  }

  validateRadioGroups() {
    return this.requiredRadioGroupTargets.every((group) => {
      return this.isRadioGroupValid(group);
    });
  }

  validateCheckboxGroups() {
    return this.requiredCheckboxGroupTargets.every((group) => {
      return this.isCheckboxGroupValid(group);
    });
  }

  validateTextInputs() {
    return this.requiredTextInputTargets.every((input) => {
      return this.isTextInputValid(input);
    });
  }

  validateFileInputs() {
    return this.requiredFileInputTargets.every((input) => {
      return this.isValidFileInput(input);
    });
  }

  validateForm() {
    const radioGroupsValid = this.validateRadioGroups();
    const checkboxGroupsValid = this.validateCheckboxGroups();
    const textInputsValid = this.validateTextInputs();
    const fileInputsValid = this.validateFileInputs();
    const validInputs = radioGroupsValid && checkboxGroupsValid && textInputsValid && fileInputsValid;
    const formChangedValid = !this.disableUntilChangedValue || this.hasFormChanged();
    const disabled = !validInputs || !formChangedValid;

    this.element.classList.toggle("invalid", !validInputs);
    this.submitTarget.disabled = disabled;
  }

  submitForm() {
    this.element.requestSubmit();
  }
}
