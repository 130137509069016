import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cart-item"
export default class extends Controller {
  static values = {
    productId: String, // ID of the product
    gaItem: Object, // GA item
  };

  fireEvent(eventName, detail = {}) {
    const event = new CustomEvent(`cart-item:${eventName}`, {
      bubbles: true,
      detail: {
        productId: this.productIdValue,
        gaItem: this.gaItemValue,
        ...detail,
      },
    });
    this.element.dispatchEvent(event);
  }

  remove() {
    this.fireEvent("remove");
  }

  connect() {
    this.element.setAttribute("data-cart-item-connected", true);
  }

  disconnect() {
    this.element.removeAttribute("data-cart-item-connected");
  }

  updateQuantity(event) {
    if (event.target.value.trim() === "") {
      return;
    }

    this.fireEvent("modify", { quantity: event.target.value });
  }

  updatePrice(event) {
    this.fireEvent("modify", { priceId: event.target.value });
  }
}
