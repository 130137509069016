import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "copiedIndicator"]

  copy(event) {
    event.preventDefault();

    navigator.clipboard.writeText(this.sourceTarget.value);

    this.copiedIndicatorTarget.classList.remove("hidden");

    setTimeout(() => {
      this.copiedIndicatorTarget.classList.add("hidden");
    }, 2000);
  }
}
