import { Controller } from "@hotwired/stimulus";
import { fetchWithCsrf } from "../helpers";

// Connects to data-controller="order-form"
export default class extends Controller {
  static values = {
    deliveryUnavailableMessage: String, // Message to display when delivery is unavailable
    invalidZipcodeMessage: String, // Message to display when the zipcode is invalid
    pricingPath: String, // The path to used to fetch pricing based on the chosen location
    serviceableLocationsPath: String, // The path to used to fetch serviceable locations based on the zipcode
    zipcodeMinOnInput: { type: Number, default: 5 }, // The minimum number of characters required on input before fetching serviceable locations
  };

  static targets = [
    "creditCardInput", // The individual input fields for credit card information
    "creditCardFields", // The container for the credit card information
    "zipcodeInput", // The input field for the zipcode
    "locationSelect", // The select field for the location
    "paymentTotals", // The container for the payment totals
  ];

  connect() {
    const options = this.getLocationSelectOptions();

    if (!options.length) {
      this.disableLocationSelect();
    }

    this.element.setAttribute("data-order-form-initialized", true);
  }

  paymentTotalsTargetConnected() {
    const orderTotal = parseFloat(this.paymentTotalsTarget.dataset.orderTotal);
    const showCreditCardFields = orderTotal > 0;

    this.creditCardInputTargets.forEach((input) => {
      if (showCreditCardFields) {
        input.setAttribute("required", true);
      } else {
        input.removeAttribute("required");
      }
    });

    this.creditCardFieldsTarget.classList.toggle("!hidden", !showCreditCardFields);
  }

  disableLocationSelect() {
    this.locationSelectTarget.disabled = true;
    this.locationSelectTarget.parentElement.parentElement.classList.add("!hidden");
  }

  enableLocationSelect() {
    this.locationSelectTarget.disabled = false;
    this.locationSelectTarget.parentElement.parentElement.classList.remove("!hidden");
  }

  async errorFromResponse(response) {
    return response
      .json()
      .then((json) => {
        return json.message;
      })
      .catch(() => {
        return "Something went wrong. Please try again.";
      });
  }

  async fetchServiceableLocations(zipcode) {
    const endpoint = `${this.serviceableLocationsPathValue}?zipcode=${zipcode}`;
    const response = await fetchWithCsrf(endpoint);

    if (!response.ok) {
      const errorMessage = await this.errorFromResponse(response);
      throw new Error(errorMessage);
    }

    return response.json();
  }

  async fetchPricing(locationId) {
    const endpoint = `${this.pricingPathValue}?location_id=${locationId}`;
    const response = await fetchWithCsrf(endpoint, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (!response.ok) {
      const errorMessage = await this.errorFromResponse(response);
      throw new Error(errorMessage);
    }

    return response.text().then((html) => Turbo.renderStreamMessage(html));
  }

  getZipcodeErrors() {
    return this.zipcodeInputTarget.parentElement.querySelectorAll(".error");
  }

  getLocationsSelectErrors() {
    return this.locationSelectTarget.parentElement.querySelectorAll(".error");
  }

  clearZipcodeErrors() {
    const zipcodeErrors = this.getZipcodeErrors();
    zipcodeErrors.forEach((error) => error.remove());
  }

  clearLocationSelectErrors() {
    const locationSelectErrors = this.getLocationsSelectErrors();
    locationSelectErrors.forEach((error) => error.remove());
  }

  buildErrorElement(message) {
    const span = document.createElement("span");
    span.classList.add("error");
    span.textContent = message;
    return span;
  }

  renderZipcodeError(message) {
    this.clearZipcodeErrors();
    const span = this.buildErrorElement(message);
    this.zipcodeInputTarget.after(span);
  }

  renderPaymentTotalsError(message) {
    const span = this.buildErrorElement(message);
    this.paymentTotalsTarget.innerHTML = "";
    this.paymentTotalsTarget.appendChild(span);
  }

  getLocationSelectOptions() {
    const options = this.locationSelectTarget.querySelectorAll('option:not([value=""])');
    return Array.from(options);
  }

  validateZipcode({ renderErrors = true } = {}) {
    const zipcodeRegex = new RegExp(/^\d{5}$/);
    const { value } = this.zipcodeInputTarget;
    const isValid = zipcodeRegex.test(value);

    if (!isValid && renderErrors) {
      this.renderZipcodeError(this.invalidZipcodeMessageValue);
    }

    return isValid;
  }

  removeLocationOptions() {
    const options = this.getLocationSelectOptions();
    options.forEach((option) => option.remove());

    if (this.locationSelectTarget.value) {
      this.locationSelectTarget.value = "";
      this.locationSelectTarget.dispatchEvent(new Event("change"));
    }
  }

  buildLocationOption(location) {
    const option = document.createElement("option");
    option.value = location.id;
    option.textContent = location.option_label;
    return option;
  }

  appendLocationOptions(locations) {
    locations.forEach((location) => {
      this.locationSelectTarget.appendChild(this.buildLocationOption(location));
    });
  }

  selectLocation(locationId) {
    this.locationSelectTarget.value = locationId;
    this.locationSelectTarget.dispatchEvent(new Event("change"));
  }

  updateLocationSelect(serviceableLocations) {
    const hasNoServiceableLocations = serviceableLocations.length === 0;
    const hasOneServiceableLocation = serviceableLocations.length === 1;
    this.clearLocationSelectErrors();

    if (hasNoServiceableLocations) {
      this.disableLocationSelect();
      this.renderZipcodeError(this.deliveryUnavailableMessageValue);
      return;
    }

    this.appendLocationOptions(serviceableLocations);
    this.enableLocationSelect();

    if (hasOneServiceableLocation) {
      this.selectLocation(serviceableLocations[0].id);
    }
  }

  onZipcodeInput(event) {
    const { value } = event.target;
    const zipcode = value.trim();
    this.clearZipcodeErrors();
    this.removeLocationOptions();

    if (zipcode.length < this.zipcodeMinOnInputValue) {
      this.disableLocationSelect();
      return;
    }

    if (!this.validateZipcode()) {
      return;
    }

    this.fetchServiceableLocations(zipcode)
      .then((locations) => this.updateLocationSelect(locations))
      .catch((error) => this.renderZipcodeError(error.message));
  }

  onLocationChange(event) {
    const locationId = event.target.value;
    this.fetchPricing(locationId).catch((error) => {
      this.renderPaymentTotalsError(error.message);
    });
  }
}
