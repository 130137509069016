import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

// Connects to data-controller="sortable-images"
export default class extends Controller {
  static targets = ["container"];

  connect() {
    Sortable.create(this.containerTarget, {
      animation: 150,
      handle: ".drag-handle",
      onEnd: () => this.updateOrder(),
    });

    // Listen for changes on remove checkboxes
    this.containerTarget.addEventListener("change", (e) => {
      if (e.target.type === "checkbox") {
        this.updateOrder();
      }
    });
  }

  updateOrder() {
    const imageIds = Array.from(this.containerTarget.querySelectorAll(".image-item"))
      .filter((item) => {
        const checkbox = item.querySelector('input[type="checkbox"]');
        return !checkbox.checked; // Only include images not marked for removal
      })
      .map((item) => item.dataset.imageId.toString())
      .filter(Boolean);

    const orderInput = document.getElementById("product_ordered_image_ids");
    orderInput.value = imageIds.join(","); // Send as comma-separated string
  }
}
