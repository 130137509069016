import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-filters"
export default class extends Controller {
  static targets = ["filterSection", "toggleButton"];

  toggle() {
    this.filterSectionTarget.classList.toggle("hidden");
    const isHidden = this.filterSectionTarget.classList.contains("hidden");
    const showText = this.toggleButtonTarget.dataset.showText;
    const hideText = this.toggleButtonTarget.dataset.hideText;
    this.toggleButtonTarget.textContent = isHidden ? showText : hideText;
  }
}
