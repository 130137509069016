import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    siteKey: String,
    testMode: { type: Boolean, default: false },
  };

  connect() {
    this.ensureRecaptchaLoaded(() => {
      this.renderRecaptcha();
    });

    this.element.setAttribute("data-recaptcha-v2-loaded", "true");
  }

  disconnect() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  ensureRecaptchaLoaded(callback) {
    if (this.testModeValue) {
      callback();
      return;
    }

    if (typeof grecaptcha !== "undefined" && typeof grecaptcha.render === "function") {
      callback();
    } else {
      this.loadRecaptchaScript(() => {
        this.checkInterval = setInterval(() => {
          if (typeof grecaptcha !== "undefined" && typeof grecaptcha.render === "function") {
            clearInterval(this.checkInterval);
            callback();
          }
        }, 100); // Check every 100ms
      });
    }
  }

  loadRecaptchaScript(callback) {
    if (document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]')) {
      callback();
      return;
    }

    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    script.onload = callback;
    document.head.appendChild(script);
  }

  renderRecaptcha() {
    if (typeof grecaptcha !== "undefined" && typeof grecaptcha.render === "function") {
      grecaptcha.render(this.element, {
        sitekey: this.siteKeyValue,
        callback: this.setRecaptchaResponse.bind(this),
      });
    } else {
      console.error("grecaptcha is not loaded properly.");
    }
  }

  setRecaptchaResponse(response) {
    const recaptchaResponseField = document.querySelector('input[name="g-recaptcha-response"]');
    if (recaptchaResponseField) {
      recaptchaResponseField.value = response;
    }
  }
}
