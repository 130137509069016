import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  hide() {
    this.element.className = "hidden";
    this.element.parentElement.parentElement.appendChild(this.element);
    this.removeRequiredFields();
  }

  removeRequiredFields() {
    const requiredFields = this.element.querySelectorAll("[required]");
    requiredFields.forEach((input) => {
      input.removeAttribute("required");
    });
  }
}
