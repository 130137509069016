import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="option-filters"
export default class extends Controller {
  static targets = [
    "option", // Each checkbox input
    "optionsContainer", // Container that holds all the options
    "showMoreButton", // Button that toggles visibility of options
  ];
  static values = {
    showLessText: String, // Text to show when the "Show More" button is expanded
    showMoreText: String, // Text to show when the "Show More" button is collapsed
    visibleLimit: { type: Number, default: 5 }, // Number of options to show before showing the "Show More" button
  };

  connect() {
    this.originalOrder = Array.from(this.optionTargets);
    this.updateVisibility();
  }

  updateVisibility() {
    const selectedOptions = this.optionTargets.filter((option) => option.checked);
    const showAll = this.showMoreButtonTarget.getAttribute("aria-expanded") === "true";
    const visibleLimit = this.calculateVisibleLimit(selectedOptions);
    const optionsToShow = showAll ? this.originalOrder : this.getOptionsToShow(selectedOptions);

    optionsToShow.forEach((option, index) => {
      const shouldShow = showAll || index < visibleLimit;
      this.toggleOptionVisibility(option, shouldShow);
      this.optionsContainerTarget.appendChild(option.parentElement);
    });

    this.updateShowMoreButton(visibleLimit, selectedOptions.length);
  }

  // If there are more selected options than the visible limit, show all selected options
  calculateVisibleLimit(selectedOptions) {
    return selectedOptions.length > this.visibleLimitValue ? selectedOptions.length : this.visibleLimitValue;
  }

  // Ensure selected options are shown first
  getOptionsToShow(selectedOptions) {
    const unselectedOptions = this.optionTargets.filter((option) => !option.checked);
    return [...selectedOptions, ...unselectedOptions];
  }

  toggleOptionVisibility(option, shouldShow) {
    const parent = option.closest(".filter-option");
    parent.setAttribute("aria-hidden", !shouldShow);
  }

  updateShowMoreButton(visibleLimit, selectedCount) {
    const shouldShowButton = this.originalOrder.length > visibleLimit || selectedCount > visibleLimit;
    this.showMoreButtonTarget.classList.toggle("hidden", !shouldShowButton);
  }

  toggleShowMore() {
    const isExpanded = this.showMoreButtonTarget.getAttribute("aria-expanded") === "true";
    this.showMoreButtonTarget.setAttribute("aria-expanded", !isExpanded);
    this.showMoreButtonTarget.textContent = isExpanded ? this.showMoreTextValue : this.showLessTextValue;
    this.updateVisibility();
  }
}
