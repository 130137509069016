import { Controller } from "@hotwired/stimulus";

/**
 * Manages showing and hiding tab panels based on tab control clicks and ARIA attributes.
 */
export default class extends Controller {
  static targets = ["tabValue", "noticeState", "tabControl", "tabPanel"];

  connect() {
    this.tabContainer = this.element;
    this.selectedPriceType = null;
    this.addEventListeners();
    this.selectInitialTab();
    this.element.setAttribute("data-tabpanel-initialized", "true");
  }

  disconnect() {
    this.removeEventListeners();
  }

  // Event Handlers
  async setSelectedTab(event) {
    const tabControl = event.currentTarget;
    if (!tabControl) return;

    this.updateTabPanel(tabControl);

    if (event.type === "click") {
      await this.checkAndShowModal();
    }
  }

  toggleTabPanels(event) {
    if (!this.isValidKeyboardEvent(event)) {
      return true; // allow event bubbling
    }

    this.updateTabPanel(event.currentTarget);
    return true;
  }

  addEventListeners() {
    this.boundToggleTabPanels = this.toggleTabPanels.bind(this);

    this.tabControlTargets.forEach((tabControl) => {
      tabControl.addEventListener("click", this.boundToggleTabPanels);
      tabControl.addEventListener("keydown", this.boundToggleTabPanels);
    });
  }

  removeEventListeners() {
    this.tabControlTargets.forEach((tabControl) => {
      tabControl.removeEventListener("click", this.boundToggleTabPanels);
      tabControl.removeEventListener("keydown", this.boundToggleTabPanels);
    });
  }

  selectInitialTab() {
    const selectedTab = this.findInitialTab();
    if (selectedTab) {
      this.updateTabPanel(selectedTab);
    }
  }

  findInitialTabFromCategoryDisplayPrice() {
    if (!this.hasNoticeStateTarget) {
      return;
    }

    const categoryDisplayPrice = this.noticeStateTarget.dataset.tabpanelCategoryDisplayPriceValue || "1";
    return this.tabControlTargets.find((tab) => tab.dataset.tabpanelPaymentOptionId === categoryDisplayPrice);
  }

  findInitialTab() {
    const fallBackTab = this.tabControlTargets.find((tab) => tab.getAttribute("aria-selected") === "true") || this.tabControlTargets[0];
    const initialTabFromCategoryDisplayPrice = this.findInitialTabFromCategoryDisplayPrice();

    return initialTabFromCategoryDisplayPrice || fallBackTab;
  }

  async checkAndShowModal() {
    const fromGoogleShopping = this.noticeStateTarget.dataset.tabpanelFromGoogleShoppingValue === "true";
    if (!fromGoogleShopping) return;

    const modalUrl = this.noticeStateTarget.dataset.tabpanelModalUrlValue;
    const response = await fetch(modalUrl);
    if (!response.ok) return;

    const html = await response.text();
    if (!html.trim()) return;

    this.appendModalToBody(html);
  }

  appendModalToBody(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const modalElement = doc.body.firstElementChild;
    if (modalElement) {
      document.body.appendChild(modalElement);
    }
  }

  updateTabPanel(tabControl) {
    this.hideAllPanels();
    this.showSelectedPanel(tabControl);
    this.updateTabControls(tabControl);
    this.updateTabValue(tabControl);
  }

  hideAllPanels() {
    this.tabPanelTargets.forEach((panel) => panel.classList.add("!hidden"));
  }

  showSelectedPanel(tabControl) {
    const tabPanel = this.tabContainer.querySelector(`#${tabControl.getAttribute("aria-controls")}`);
    tabPanel.classList.remove("!hidden");
  }

  updateTabControls(selectedTab) {
    this.tabControlTargets.forEach((control) => control.setAttribute("aria-selected", false));
    selectedTab.setAttribute("aria-selected", true);
  }

  updateTabValue(tabControl) {
    this.tabValueTarget.value = tabControl.dataset.tabpanelValueValue;
    this.firePriceTypeSelectedEvent(tabControl);
  }

  firePriceTypeSelectedEvent(tabControl) {
    const event = new CustomEvent("rentone:price-type-selected", {
      detail: { priceType: tabControl.dataset.tabpanelGaPriceType },
    });
    window.dispatchEvent(event);
  }

  isValidKeyboardEvent(event) {
    return event.type !== "keydown" || event.key === "Enter" || event.key === " ";
  }
}
