import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="google-analytics"
export default class extends Controller {
  static values = {
    category: Object,
    items: Array,
    item: Object,
    promotion: Object,
    fireViewItemOnConnect: { type: Boolean, default: false },
    fireViewCartOnConnect: { type: Boolean, default: false },
    fireAddToCartOnConnect: { type: Boolean, default: false },
    firePurchaseOnConnect: { type: Boolean, default: false },
    listenForPriceType: { type: Boolean, default: false },
    weeklyPrice: { type: Number, default: 0 },
    monthlyPrice: { type: Number, default: 0 },
    weeklyPayments: { type: Number, default: 0 },
    costOfLeaseServices: { type: Number, default: 0 },
    oldPrice: { type: Number, default: 0 },
    rtoTotal: { type: Number, default: 0 },
    itemDescription: String,
    itemImage: String,
    itemCategory: String,
    isOnSale: { type: Boolean, default: false },
    promoCode: String,
    total: { type: Number, default: 0 },
    transactionId: String,
    purchaseValue: Number,
    userEmailAddress: String,
    formName: String,
    formData: { type: Object, default: {} },
  };

  connect() {
    this.selectedPriceType = null;

    if (this.hasItemsValue && this.hasCategoryValue) {
      window.googleAnalytics.viewItemList({
        category: this.categoryValue,
        items: this.itemsValue,
      });
    }

    if (this.hasItemValue && this.fireViewItemOnConnectValue) {
      this.viewItem();
    }

    if (this.hasItemsValue && this.fireViewCartOnConnectValue) {
      this.viewCart();
    }

    if (this.hasItemValue && this.fireAddToCartOnConnectValue) {
      this.addToCart();
    }

    if (this.hasItemsValue && this.firePurchaseOnConnectValue) {
      this.purchase();
    }

    if (this.listenForPriceTypeValue) {
      this.listenForPriceType();
    }

    if (this.hasFormNameValue) {
      this.element.addEventListener("turbo:submit-end", this.onFormSubmit);
    }
  }

  disconnect() {
    if (this.listenForPriceTypeValue) {
      window.removeEventListener("rentone:price-type-selected", this.onPriceTypeSelected);
    }

    if (this.hasFormNameValue) {
      this.element.removeEventListener("turbo:submit-end", this.onFormSubmit);
    }
  }

  onFormSubmit = (event) => {
    if (!event.detail.success) {
      return;
    }

    window.googleAnalytics.submitForm({
      formName: this.formNameValue,
      formElement: this.element,
      formData: this.formDataValue,
    });
  };

  onPriceTypeSelected = (event) => {
    this.selectedPriceType = event.detail.priceType;
  };

  listenForPriceType() {
    window.addEventListener("rentone:price-type-selected", this.onPriceTypeSelected);
  }

  viewItem() {
    if (!this.hasItemValue) {
      return;
    }

    const productData = {
      item: this.itemValue,
      itemDescription: this.itemDescriptionValue,
      itemImage: this.itemImageValue,
      itemCategory: this.itemCategoryValue,
      oldPrice: this.oldPriceValue,
      rtoTotal: this.rtoTotalValue,
      weeklyPrice: this.weeklyPriceValue,
      monthlyPrice: this.monthlyPriceValue,
      weeklyPayments: this.weeklyPaymentsValue,
      costOfLeaseServices: this.costOfLeaseServicesValue,
      isOnSale: this.isOnSaleValue,
    };

    window.googleAnalytics.viewItem(productData);
  }

  viewCart() {
    if (!this.hasItemsValue) {
      return;
    }

    window.googleAnalytics.viewCart({
      items: this.itemsValue,
    });
  }

  addToCart() {
    if (!this.hasItemValue) {
      return;
    }

    let item = this.itemValue;

    if (this.selectedPriceType) {
      item.item_payment_type = this.selectedPriceType;
    }

    window.googleAnalytics.addToCart({ item });
  }

  selectItem() {
    if (!this.hasItemValue) {
      return;
    }

    window.googleAnalytics.selectItem({
      item: this.itemValue,
    });
  }

  beginCheckout() {
    if (!this.hasItemsValue) {
      return;
    }

    window.googleAnalytics.beginCheckout({
      items: this.itemsValue,
      total: this.totalValue,
      promoCode: this.promoCodeValue.length > 0 ? this.promoCodeValue : null,
    });
  }

  selectPromotion() {
    if (!this.hasPromotionValue) {
      return;
    }

    window.googleAnalytics.selectPromotion({
      promotionId: this.promotionValue.promotion_id,
      promotionName: this.promotionValue.promotion_name,
      creativeName: this.promotionValue.creative_name,
      creativeSlot: this.promotionValue.creative_slot,
    });
  }

  purchase() {
    if (!this.hasTransactionIdValue) {
      return;
    }

    const firedTransactionIds = JSON.parse(localStorage.getItem("firedTransactionIds") || "[]");
    if (firedTransactionIds.includes(this.transactionIdValue)) {
      return;
    }

    window.googleAnalytics.purchase({
      items: this.itemsValue,
      transactionId: this.transactionIdValue,
      value: this.purchaseValueValue,
      userEmailAddress: this.userEmailAddressValue,
    });

    firedTransactionIds.push(this.transactionIdValue);
    localStorage.setItem("firedTransactionIds", JSON.stringify(firedTransactionIds));
  }
}
