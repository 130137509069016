import { debounce } from "lodash";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    mobile: Boolean
  };

  connect() {
    this.adjustPosition();
    this.setupObserver();
    this.setupResizeListener();
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener('resize', this.debouncedAdjust);
  }

  adjustPosition() {
    const injectedBanners = document.getElementsByClassName('fw-cta-docked-container fw-cta-sidebar-container fw-cta-sidebar-right');
    
    if (!injectedBanners.length) return;

    const injectedElement = injectedBanners[Math.max(this.mobileValue ? 1 : 0, 0)];
    const ourBanner = this.element;

    if (injectedElement && ourBanner) {
      ourBanner.style.position = 'fixed';
      ourBanner.style.top = `${injectedElement.offsetTop + injectedElement.offsetHeight}px`;
      ourBanner.style.right = '0';
    }
  }

  setupObserver() {
    this.observer = new MutationObserver(() => this.adjustPosition());
    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  setupResizeListener() {
    this.debouncedAdjust = debounce(() => this.adjustPosition(), 200);
    window.addEventListener('resize', this.debouncedAdjust);
  }
}
