import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper/bundle";

// Connects to data-controller="swiper"
export default class extends Controller {
  static values = {
    autoplayDelay: { type: Number, default: 5000 },
    loop: { type: Boolean, default: false },
    pagination: { type: Boolean, default: false },
  };

  connect() {
    this.swiper = new Swiper(this.element, this.buildConfig());
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }
  }

  buildConfig = () => {
    const config = {
      loop: this.loopValue,
      autoplay: {
        delay: this.autoplayDelayValue,
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
    };

    if (this.paginationValue) {
      config.pagination = {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
      };
    }

    return config;
  }
}
