import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = [
    "content", // The content that will expand and collapse
  ];
  static values = {
    expandFirst: { type: Boolean, default: false }, // When true, the first content will be expanded on connect
  };

  connect() {
    if (this.expandFirstValue && this.contentTargets.length > 0) {
      this.expand(this.contentTargets[0]);
    }

    this.onResize = throttle(this.onResize, 100);
    window.addEventListener("resize", this.onResize);
  }

  disconnect() {
    window.removeEventListener("resize", this.onResize);
  }

  toggle(event) {
    const button = event.currentTarget;
    const contentId = button.getAttribute("aria-controls");
    const content = this.contentTargets.find((content) => content.id === contentId);

    if (!content) {
      console.debug(`Content with ID ${contentId} not found`);
      return;
    }

    this.contentTargets.forEach((target) => {
      if (target !== content) {
        this.collapse(target);
      }
    });

    if (content.style.maxHeight) {
      this.collapse(content);
    } else {
      this.expand(content);
    }
  }

  buttonForContent(content) {
    return this.element.querySelector(`button[aria-controls="${content.id}"]`);
  }

  expand(content) {
    content.style.maxHeight = content.scrollHeight + "px";
    content.setAttribute("aria-hidden", "false");
    this.buttonForContent(content).setAttribute("aria-expanded", "true");
  }

  collapse(content) {
    content.style.maxHeight = null;
    content.setAttribute("aria-hidden", "true");
    this.buttonForContent(content).setAttribute("aria-expanded", "false");
  }

  onResize = () => {
    const expandedContent = this.contentTargets.find((content) => content.style.maxHeight);
    if (expandedContent) {
      this.expand(expandedContent);
    }
  };
}
