import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="conditional-fields"
export default class extends Controller {
  static values = {
    showValue: { type: String, default: "" }, // Value that triggers the conditional fields to show if the trigger input is a select tag
  };

  static targets = [
    "triggerInput", // A select tag or checkbox input that triggers the conditional fields to show
    "fieldToShow", // Field to show when the trigger input is checked or a specific value is selected
    "fieldToHide", // Field to hide when the trigger input is unchecked or a specific value is not selected
  ];

  connect() {
    if (!this.triggerInputTarget) {
      throw new Error(`No trigger input found for conditional fields controller on ${this.element}`);
    }

    this.updateVisibility();
    this.triggerInputTarget.addEventListener("change", this.updateVisibility);
  }

  disconnect() {
    this.triggerInputTarget.removeEventListener("change", this.updateVisibility);
  }

  shouldShow() {
    if (this.triggerInputTarget.type === "checkbox") {
      return this.triggerInputTarget.checked;
    }

    return this.triggerInputTarget.value === this.showValueValue;
  }

  updateVisibility = () => {
    this.fieldToShowTargets.forEach((field) => {
      field.classList.toggle("!hidden", !this.shouldShow());
    });
    this.fieldToHideTargets.forEach((field) => {
      field.classList.toggle("!hidden", this.shouldShow());
    });
  };
}
