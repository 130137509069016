import { Controller } from "@hotwired/stimulus";
import { fetchWithCsrf } from "../helpers";
import { debounce } from "lodash";

// Connects to data-controller="slug-verifier"
export default class extends Controller {
  VERIFY_SLUG_DEBOUNCE_TIME = 250;

  static values = {
    categoryId: String, // The category ID
    generateSlugUrl: String, // API endpoint to generate slug
    verifySlugUrl: String, // API endpoint to verify slug
  };

  static targets = [
    "copyButton", // Button to copy the slug to the clipboard
    "nameInput", // Input field for the name
    "slugInput", // Input field for the slug
    "slugPreview", // Div to display the slug preview
    "slugErrors", // Div to display the slug errors
    "copyButton", // Button to copy the slug to the clipboard
    "slugPreviewUrlContainer", // Div to display the slug preview URL
    "slugPreviewHref", // Link to display the slug preview URL
  ];

  connect() {
    this.verifySlug = debounce(this.verifySlug, this.VERIFY_SLUG_DEBOUNCE_TIME);
    this.copyButtonTarget.addEventListener("click", this.copyUrlToClipboard);
    this.slugInputTarget.addEventListener("input", this.verifySlug);

    if (this.categoryIdValue === "") {
      this.nameInputTarget.addEventListener("change", this.generateSlug);
    }

    if (this.slugInputTarget.value.length === 0) {
      this.hideSlugPreview();
    } else {
      this.showSlugPreview();
    }
  }

  disconnect() {
    this.copyButtonTarget.removeEventListener("click", this.copyUrlToClipboard);
    this.nameInputTarget.removeEventListener("change", this.generateSlug);
    this.slugInputTarget.removeEventListener("input", this.verifySlug);
  }

  copyUrlToClipboard = async () => {
    if (!this.hasSlugPreviewHrefTarget) {
      return;
    }

    const url = this.slugPreviewHrefTarget.textContent;
    await navigator.clipboard.writeText(url);

    this.copyButtonTarget.classList.add("copied");

    setTimeout(() => {
      this.copyButtonTarget.classList.remove("copied");
    }, 2000);
  };

  updateSlugPreviewUrl(url) {
    this.slugPreviewUrlContainerTarget.innerHTML = "";
    const a = document.createElement("a");
    a.href = url;
    a.textContent = url;
    a.target = "_blank";
    a.dataset.slugVerifierTarget = "slugPreviewHref";
    this.slugPreviewUrlContainerTarget.appendChild(a);
  }

  showSlugPreview(url = null) {
    if (url) {
      this.updateSlugPreviewUrl(url);
    }

    this.slugPreviewTarget.classList.remove("!hidden");
  }

  hideSlugPreview() {
    this.slugPreviewTarget.classList.add("!hidden");
  }

  clearErrors() {
    this.slugErrorsTarget.innerHTML = "";
  }

  clearSlugInput() {
    this.slugInputTarget.value = "";
    this.clearErrors();
    this.hideSlugPreview();
  }

  buildError(errorMessage) {
    const p = document.createElement("p");
    p.classList.add("error");
    p.textContent = errorMessage;
    return p;
  }

  handleUnknownError(error) {
    this.renderErrors("Something went wrong, please try again later or contact support.");
  }

  renderErrors(errors) {
    this.clearErrors();

    if (Array.isArray(errors)) {
      errors.forEach((error) => {
        this.slugErrorsTarget.appendChild(this.buildError(error));
      });
    } else {
      this.slugErrorsTarget.appendChild(this.buildError(errors));
    }
  }

  async handleSlugResponse(response) {
    const data = await response.json().catch((error) => {
      this.handleUnknownError(error);
    });

    if (response.ok) {
      this.slugInputTarget.value = data.slug;
      this.clearErrors();
      this.showSlugPreview(data.url);
    } else if (data.errors) {
      this.renderErrors(data.errors);
      this.hideSlugPreview();
    } else {
      this.handleUnknownError(response);
    }
  }

  generateSlug = async () => {
    if (this.nameInputTarget.value.length === 0) {
      this.clearSlugInput();
      return;
    }

    const response = await fetchWithCsrf(this.generateSlugUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        category: {
          name: this.nameInputTarget.value,
        },
      }),
    }).catch((error) => {
      this.handleUnknownError(error);
    });

    this.handleSlugResponse(response);
  };

  verifySlug = async () => {
    if (this.slugInputTarget.value.length === 0) {
      this.clearSlugInput();
      return;
    }

    const response = await fetchWithCsrf(this.verifySlugUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        category: {
          slug: this.slugInputTarget.value,
          id: this.categoryIdValue,
        },
      }),
    }).catch((error) => {
      this.handleUnknownError(error);
    });

    this.handleSlugResponse(response);
  };
}
