import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["marker"];

  connect() {
    this.markerTargets.forEach(marker => {
      marker.addEventListener("click", this.showInfoWindow.bind(this));
    });
  }

  disconnect() {
    this.markerTargets.forEach(marker => {
      marker.removeEventListener("click", this.showInfoWindow.bind(this));
    });
  }

  showInfoWindow(event) {
    const marker = event.currentTarget;
    const locationId = marker.dataset.locationId;
    const infoContent = document.getElementById(`info-window-${locationId}`).innerHTML;
    this.displayInfoWindow(marker, infoContent);
  }

  displayInfoWindow(marker, content) {
    const infoWindow = new google.maps.InfoWindow({
      content: content,
    });
    const position = {
      lat: parseFloat(marker.getAttribute('position').split(',')[0]),
      lng: parseFloat(marker.getAttribute('position').split(',')[1])
    };
    infoWindow.setPosition(position);
    infoWindow.open(marker.map);
  }
}
