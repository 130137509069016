import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["overlay", "modal", "iframe"];
  static values = {
    allowedOrigin: { type: String, default: "https://atlas-games-e7b251f3a47d.herokuapp.com" }
  };

  connect() {
    this.overlay = document.getElementById('gamifyModalOverlay');
    this.modal = document.querySelector('.gamifyModal');
    this.iframe = document.getElementById('gamifyModalIframe');
  }

  open(event) {
    const iframeSrc = event.currentTarget.getAttribute('data-iframe-src');
    if (!iframeSrc) {
      console.error('openModal: data-iframe-src attribute is missing or empty');
      return;
    }

    this.iframe.src = iframeSrc;
    this.overlay.classList.remove('hidden');
    this.modal.classList.remove('hidden');
  }

  close() {
    this.iframe.src = "";
    this.overlay.classList.add('hidden');
    this.modal.classList.add('hidden');
  }

  handleMessage(event) {
    if (event.origin !== this.allowedOriginValue) {
      return;
    }

    if (event.data === 'closeme') {
      this.close();
      return;
    }
  }
}
