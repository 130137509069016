import { Controller } from "@hotwired/stimulus";
import "@hotwired/turbo";

export default class extends Controller {
  static targets = ["fileFormatSelect", "customFormatFields"]

  connect() {
    this.toggleCustomFormatFields();
    this.fileFormatSelectTarget.addEventListener('change', this.toggleCustomFormatFields.bind(this));
  }

  disconnect() {
    this.fileFormatSelectTarget.removeEventListener('change', this.toggleCustomFormatFields.bind(this));
  }

  toggleCustomFormatFields() {
    if (this.fileFormatSelectTarget.value === 'custom') {
      this.customFormatFieldsTarget.style.display = 'block';
    } else {
      this.customFormatFieldsTarget.style.display = 'none';
    }
  }
}
