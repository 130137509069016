import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="header"
export default class extends Controller {
  SCROLL_THRESHOLD = 50; // After this scroll amount, we add an active class to the header

  static targets = [
    "searchContainer", // Container whose visibility is toggled by a button on mobile
    "searchInput", // Input field that should be focused when the search container is shown
    "shopNavContainer", // Container whose visibility is toggled by a button on mobile
    "shopNav", // Nav element that should be toggled when the shop nav container is shown
    "panel", // Each individual panel in the shop nav
  ];

  static values = {
    currentPanel: String,
  };

  connect() {
    this.currentPanelValue = "root";
    window.addEventListener("scroll", this.handleScroll);
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  toggleSearch(event) {
    const button = event.currentTarget;
    const shouldExpand = button.getAttribute("aria-expanded") !== "true";
    button.setAttribute("aria-expanded", shouldExpand);
    this.searchContainerTarget.classList.toggle("active", shouldExpand);

    if (shouldExpand) {
      this.searchInputTarget.focus();
    }
  }

  handleScroll = throttle(() => {
    const scrollTop = window.scrollY;
    this.element.classList.toggle("active", scrollTop > this.SCROLL_THRESHOLD);
  }, 100);

  lockScroll = () => {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.setProperty("--body-padding-right", `${scrollBarWidth}px`);
    document.body.classList.add("overflow-hidden");
  };

  unlockScroll = () => {
    document.body.style.removeProperty("--body-padding-right");
    document.body.classList.remove("overflow-hidden");
  };

  toggleNav = (event) => {
    this.lastFocusedButton = event.currentTarget;
    const shouldExpand = this.shopNavContainerTarget.getAttribute("aria-hidden") === "true";

    this.lastFocusedButton.setAttribute("aria-expanded", shouldExpand);
    this.shopNavContainerTarget.setAttribute("aria-hidden", !shouldExpand);
    this.shopNavContainerTarget.inert = !shouldExpand;
    this.shopNavTarget.classList.toggle("active", shouldExpand);

    if (shouldExpand) {
      this._trapFocus();
      this.lockScroll();
    } else {
      this.closeNav();
    }
  };

  closeNav = () => {
    const buttons = document.querySelectorAll('[aria-controls="shop-nav"]');
    buttons.forEach((button) => button.setAttribute("aria-expanded", false));

    this.shopNavContainerTarget.setAttribute("aria-hidden", true);
    this.shopNavTarget.classList.remove("active");

    this.resetMenu();

    if (this.lastFocusedButton) {
      this.lastFocusedButton.focus();
    }

    document.querySelector("main")?.removeAttribute("inert");
    this.unlockScroll();
  };

  resetMenu() {
    this.panelTargets.forEach((panel) => {
      if (panel.dataset.panelId === "root") {
        panel.classList.add("active");
        panel.classList.remove("slide-left", "slide-right");
      } else {
        panel.classList.remove("active", "slide-left", "slide-right");
      }
    });

    const allButtons = this.shopNavTarget.querySelectorAll(".menu-trigger");

    allButtons.forEach((button) => {
      button.setAttribute("aria-expanded", "false");
    });

    this.currentPanelValue = "root";
  }

  handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      this.closeNav();
    }
  };

  handleClickOutside = (event) => {
    if (!this.shopNavTarget.contains(event.target) && !event.target.closest('[aria-controls="shop-nav"]')) {
      this.closeNav();
    }
  };

  _trapFocus() {
    const nav = this.shopNavTarget;
    const focusableElements = nav.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    if (focusableElements.length === 0) return;

    const firstFocusable = focusableElements[0];
    const lastFocusable = focusableElements[focusableElements.length - 1];

    if (this.lastFocusedButton?.matches(":focus")) {
      firstFocusable.focus();
    }

    this.shopNavTarget.addEventListener("keydown", (e) => {
      if (e.key !== "Tab") return;

      if (e.shiftKey && document.activeElement === firstFocusable) {
        e.preventDefault();
        lastFocusable.focus();
      }

      if (!e.shiftKey && document.activeElement === lastFocusable) {
        e.preventDefault();
        firstFocusable.focus();
      }
    });

    document.querySelector("main")?.setAttribute("inert", "");
  }

  showSubMenu(event) {
    const button = event.currentTarget;
    const targetPanelId = button.dataset.showPanel;
    const currentPanel = button.closest("[data-panel-id]");
    const nextPanel = this.shopNavTarget.querySelector(`[data-panel-id="${targetPanelId}"]`);

    if (!nextPanel || !currentPanel) return;

    button.setAttribute("aria-expanded", "true");
    nextPanel.inert = false;

    const siblingPanels = this.shopNavTarget.querySelectorAll(
      `[data-panel-parent-id="${currentPanel.dataset.panelId}"]`
    );

    siblingPanels.forEach((panel) => {
      if (panel !== nextPanel) {
        panel.inert = true;
      }
    });

    currentPanel.classList.add("slide-left");
    nextPanel.classList.remove("slide-left", "slide-right");
    nextPanel.classList.add("active");

    this.currentPanelValue = targetPanelId;
  }

  goBack(event) {
    const currentPanel = event.currentTarget.closest("[data-panel-id]");
    const parentId = currentPanel.dataset.panelParentId;
    const parentPanel = this.panelTargets.find((panel) => panel.dataset.panelId === parentId);

    if (!parentPanel) return;

    parentPanel.inert = false;
    currentPanel.inert = true;

    const triggerButton = parentPanel.querySelector(`[data-show-panel="${currentPanel.dataset.panelId}"]`);
    if (triggerButton) {
      triggerButton.setAttribute("aria-expanded", "false");
    }

    currentPanel.classList.add("slide-right");
    currentPanel.classList.remove("active");
    parentPanel.classList.remove("slide-left", "slide-right");
    parentPanel.classList.add("active");

    this.currentPanelValue = parentId;
  }
}
