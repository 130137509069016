import { Controller } from "@hotwired/stimulus"
import { fetchWithCsrf } from "../helpers"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["checkbox", "columnList", "perPage"]

  connect() {
    this.sortable = Sortable.create(this.columnListTarget, {
      handle: '.column-drag-handle',
      animation: 150
    })
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy()
    }
  }

  apply() {
    // Ensure at least one column is selected
    if (!this.checkboxTargets.some(cb => cb.checked)) {
      alert('Please select at least one column')
      return
    }

    // Get all checkboxes in their current order
    const orderedCheckboxes = Array.from(this.columnListTarget.querySelectorAll('[data-column-preferences-target="checkbox"]'))
    
    // Get selected columns in their current order
    const selectedColumns = orderedCheckboxes
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.dataset.columnName)

    // Get all columns in their current order (for maintaining order even of hidden columns)
    const allColumns = orderedCheckboxes.map(checkbox => checkbox.dataset.columnName)

    fetchWithCsrf('/admin/column_preferences', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        columns: {
          visible: selectedColumns,
          order: allColumns,
          per_page: parseInt(this.perPageTarget.value)
        }
      })
    }).then(() => window.location.reload())
  }

  reset() {
    fetchWithCsrf('/admin/column_preferences', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        columns: {}
      })
    }).then(() => window.location.reload())
  }
}
