import { Controller } from "@hotwired/stimulus";

/**
 * Delegates a click event from the controller element to the specified target element.
 * Clicks are only delegated if the controller element is directly clicked; clicks on children are ignored.
 */
export default class DelegateClickController extends Controller {
  static values = {
    /**
     * Query selector for the element that will receive the delegated click event.
     */
    selector: String,
  };

  initialize() {
    this.controllerTarget = this.element;
    this.controlledTarget = document.querySelector(this.selectorValue);

    if (!this.controlledTarget) {
      throw new Error(`The delegate-click controller could not find an element with selector[${this.selectorValue}].`);
    }

    this.boundDelegateClick = this.delegateClick.bind(this);
    document.addEventListener('click', this.boundDelegateClick);
  }

  disconnect() {
    document.removeEventListener('click', this.boundDelegateClick);
  }

  /**
   * Delegates a click event from the controller element to the controlledTarget.
   */
  delegateClick(event) {
    if (event.target !== this.controllerTarget) {
      return; // avoid delegating clicks from child elements.
    }

    this.controlledTarget.click();
  }
}
