import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable", "checkbox"];

  connect() {
    if (this.hasCheckboxTarget) {
      this.toggleVisibility(this.checkboxTarget.checked);
      this.checkboxTarget.addEventListener('change', this.toggle);
    }
  }

  disconnect() {
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.removeEventListener('change', this.toggle);
    }
  }

  toggle = (ev) => {
    const isChecked = ev.target.checked;
    this.toggleVisibility(isChecked);
  }

  toggleVisibility = (isVisible) => {
    this.toggleableTargets.forEach(target => {
      target.classList.toggle('hidden', isVisible);

      // for form fields, we need to track required fields and remove the attribute until visibility is enabled again.
      const requiredFields = target.querySelectorAll('[required], [data-required="required"]');
      requiredFields.forEach(field => {
        if (isVisible) {
          field.setAttribute('data-required', 'required');
          field.removeAttribute('aria-required');
          field.removeAttribute('required');
        } else {
          field.setAttribute('aria-required', 'true');
          field.setAttribute('required', 'required');
          field.removeAttribute('data-required');
        }
      });
    });
  }
}
