import { Controller } from "@hotwired/stimulus";
import { EditorView, basicSetup } from "codemirror";
import { json } from "@codemirror/lang-json";
import { linter, lintGutter } from "@codemirror/lint";

// Connects to data-controller="codemirror"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    if (!this.hasInputTarget) return;

    new EditorView({
      doc: this.inputTarget.value,
      extensions: [
        basicSetup,
        json(),
        lintGutter(),
        linter(jsonLinter),
        EditorView.updateListener.of((update) => {
          if (update.docChanged) {
            this.inputTarget.value = update.state.doc.toString();
          }
        }),
        EditorView.theme({
          "&": {
            height: "300px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          },
          ".cm-scroller": {
            overflow: "auto",
          },
          ".cm-gutterElement.cm-activeLineGutter": {
            backgroundColor: "transparent",
          },
          ".cm-diagnostic": {
            padding: "1px 8px",
          },
          ".cm-diagnostic.cm-diagnostic-error": {
            backgroundColor: "#ff000015",
          },
        }),
      ],
      parent: this.element,
    });

    this.inputTarget.classList.add("!hidden");
  }
}

// JSON Linter function
function jsonLinter(view) {
  const doc = view.state.doc.toString();
  const diagnostics = [];

  try {
    if (doc.trim()) {
      JSON.parse(doc);
    }
  } catch (e) {
    // Get the position of the error from the error message
    const match = e.message.match(/at position (\d+)/);
    const pos = match ? parseInt(match[1]) : 0;

    diagnostics.push({
      from: pos,
      to: pos + 1,
      severity: "error",
      message: e.message,
      source: "JSON",
    });
  }

  return diagnostics;
}
