import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="back-to-top"
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.scrollToTop);
  }

  disconnect() {
    this.element.removeEventListener("click", this.scrollToTop);
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
}
