import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

class TomSelectController extends Controller {
  static values = {
    allowCreate: { type: Boolean, default: false }, // Set to true to allow creating new items
    createFilter: { type: String, default: ".*" }, // Items may only be created if they match this regex, allowCreate must be true
    createOnBlur: { type: Boolean, default: false }, // Set to true to create a new item when the input loses focus, allowCreate must be true
    hideNoResults: { type: Boolean, default: false }, // Set to true to hide the no results message
    selectAll: { type: Boolean, default: false } // New value to handle select_all option
  };

  static targets = ["select"];

  connect() {
    this.tomSelect = new TomSelect(this.element, {
      maxOptions: null,
      create: this.allowCreateValue,
      createFilter: this.createFilterValue,
      createOnBlur: this.createOnBlurValue,
      render: this.buildRenderOptions(),
      plugins: {
        remove_button: {
          title: "Remove this item"
        },
        clear_button: {
          title: 'Remove all selected options',
        },
        checkbox_options: {
          checkedClassNames: ['ts-checked'],
          uncheckedClassNames: ['ts-unchecked'],
        },
        dropdown_input: {},
      },
    });

    if (this.selectAllValue) {
      this.tomSelect.addItem('all');
    }

    this.element.dispatchEvent(new CustomEvent('tomselect:ready', { 
      bubbles: true, 
      detail: { tomSelect: this.tomSelect } 
    }))
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy();
      this.tomSelect = null;
    }
  }

  buildRenderOptions = () => {
    const options = {};

    if (this.hideNoResultsValue) {
      options["no_results"] = null;
    }

    return options;
  };
}

export default TomSelectController;
