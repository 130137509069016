import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email"]
  static values = {
    previousEmail: String
  }

  connect() {
    const cartItems = JSON.parse(this.emailTarget.dataset.cartItems || '[]')
    const sessionEmail = this.emailTarget.dataset.sessionEmail
    
    if (cartItems.length > 0 && sessionEmail) {
      fetch('/cart_abandonment/track', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          cart_abandonment: {
            email: sessionEmail,
            cart_items: cartItems
          }
        })
      })
    }
    
    this.previousEmailValue = this.emailTarget.value || ''
  }

  onEmailBlur(event) {
    const email = event.target.value
    if (!email || email === this.previousEmailValue) return

    const cartItems = JSON.parse(event.target.dataset.cartItems || '[]')
    if (cartItems.length === 0) return

    this.previousEmailValue = email

    fetch('/cart_abandonment/track', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        cart_abandonment: {
          email: email,
          cart_items: cartItems
        }
      })
    })
  }
}
