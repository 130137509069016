import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown", "label"]

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this))
    
    this.handleTomSelectReady = (event) => {
      this.tomSelect = event.detail.tomSelect
    }
    
    this.element.addEventListener('tomselect:ready', this.handleTomSelectReady)
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this))
    this.element.removeEventListener('tomselect:ready', this.handleTomSelectReady)
  }

  clear(event) {
    event.preventDefault()
    
    if (this.tomSelect) {
      this.tomSelect.clear(true)
      this.updateLabel({ target: { selectedOptions: [] } })
    } else {
      const dateInputs = this.element.querySelectorAll('input[type="date"]')
      dateInputs.forEach(input => {
        input.value = ''
      })
      
      this.updateLabelToDefault()
    }
    
    const applyButton = this.element.querySelector('.filters__dropdown-apply-button')
    if (applyButton) {
      applyButton.click()
    }
  }

  toggle(event) {
    event.stopPropagation()
    
    document.querySelectorAll('.filters__dropdown-content').forEach(dropdown => {
      if (dropdown !== this.dropdownTarget) {
        dropdown.classList.remove('is-open')
      }
    })

    this.dropdownTarget.classList.toggle('is-open')
  }

  updateLabel(event) {
    if (this.tomSelect) {
      const selectedValues = this.tomSelect.getValue()
      this.updateLabelWithSelection(selectedValues.length)
    } else if (event.target.selectedOptions) {
      this.updateLabelWithSelection(event.target.selectedOptions.length)
    }
  }

  updateSingleLabel(event) {
    const selectedOption = event.target.selectedOptions[0]
    if (selectedOption.value) {
      this.updateLabelWithBadge(selectedOption.text)
    } else {
      this.updateLabelToDefault()
    }
  }

  // Private methods

  updateLabelWithSelection(count) {
    if (count > 0) {
      this.updateLabelWithBadge(count)
    } else {
      this.updateLabelToDefault()
    }
  }

  updateLabelWithBadge(content) {
    // Clear existing content
    this.labelTarget.textContent = ''
    
    // Add filter name
    const nameSpan = document.createElement('span')
    nameSpan.textContent = `${this.getFilterDisplayName()}: `
    this.labelTarget.appendChild(nameSpan)
    
    // Add badge
    const badge = document.createElement('span')
    badge.className = 'filters__badge'
    badge.textContent = content
    this.labelTarget.appendChild(badge)
  }

  updateLabelToDefault() {
    this.labelTarget.textContent = `${this.getFilterDisplayName()}: All`
  }

  getFilterDisplayName() {
    const button = this.element.querySelector('button[data-filter-name]')
    return button ? button.dataset.filterName : this.titleize(this.element.querySelector('select').dataset.filterName)
  }

  titleize(str) {
    return str.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  handleClickOutside = (event) => {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.classList.remove('is-open')
    }
  }
}
