import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "label"]

  connect() {
    const checkedOption = this.optionTargets.find(option => option.checked)
    if (checkedOption) {
      this.updateSelection({ currentTarget: checkedOption })
    }
  }

  updateSelection(event) {
    const radio = event.currentTarget
    // Get the parent container (div.inline-block) of the radio button
    const optionGroup = radio.closest('div').parentElement

    // Find all labels only within this option group
    const labels = optionGroup.querySelectorAll('[data-product-options-target="label"]')
    labels.forEach(label => {
      label.classList.remove('selected')
    })

    // Find the label for this radio button within the same group
    const label = optionGroup.querySelector(`label[for="${radio.id}"]`)
    if (label) {
      label.classList.add('selected')
    }
  }
}
