import { Controller } from "@hotwired/stimulus";
import sanitizeHtml from "sanitize-html";

export default class extends Controller {
  static targets = [
    "websiteUrl",
    "source",
    "medium",
    "campaign",
    "content",
    "term",
    "generatedUrl",
  ];

  connect() {
    // Initialize URL generation on page load
    this.generateUrl();
  }

  // Format UTM parameter value according to requirements
  formatUtmValue(value) {
    return value
      .toLowerCase()
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .replace(/[^a-z0-9_-]/g, ""); // Remove any characters that aren't lowercase letters, numbers, underscores, or hyphens
  }

  // Handle input for UTM fields
  handleUtmInput(event) {
    const input = event.target;
    const formattedValue = this.formatUtmValue(input.value);

    // Only update if the value has changed to prevent cursor jumping
    if (input.value !== formattedValue) {
      input.value = formattedValue;
    }

    this.generateUrl();
  }

  generateUrl() {
    const baseUrl = this.websiteUrlTarget.value.trim();
    if (!baseUrl) return;

    const params = new URLSearchParams();
    const requiredFields = [
      this.sourceTarget,
      this.mediumTarget,
      this.campaignTarget,
    ];
    const allRequiredFieldsFilled = requiredFields.every(
      (field) => field.value.trim() !== ""
    );

    // Clear generated URL if required fields are missing
    if (!allRequiredFieldsFilled) {
      this.generatedUrlTarget.value = "";
      return;
    }

    params.append("utm_source", this.sourceTarget.value.trim());
    params.append("utm_medium", this.mediumTarget.value.trim());
    params.append("utm_campaign", this.campaignTarget.value.trim());

    if (this.contentTarget.value) {
      params.append("utm_content", this.contentTarget.value.trim());
    }
    if (this.termTarget.value) {
      params.append("utm_term", this.termTarget.value.trim());
    }

    const paramsString = params.toString();
    const generatedUrl = paramsString ? `${baseUrl}?${paramsString}` : baseUrl;
    this.generatedUrlTarget.value = generatedUrl;
  }

  async copyUrl() {
    const url = this.generatedUrlTarget.value;
    if (!url) return;

    try {
      await navigator.clipboard.writeText(url);

      // Add visual feedback for copy success
      const copyButton = this.element.querySelector(".copy-button");
      const originalText = copyButton.innerHTML;
      copyButton.innerHTML = '<i class="fa-solid fa-check"></i> Copied!';

      setTimeout(() => {
        copyButton.innerHTML = sanitizeHtml(originalText);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy URL");
    }
  }
}
