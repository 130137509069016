import { Controller } from "@hotwired/stimulus";

// Lazy loading turbo frames does not work when the target frame has "display: contents;" styling.
// This controller utilizes IntersectionObserver to lazy load the target frame by
// setting the src attribute on the controller element when a specified "trigger"
// element is intersected.
// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = [
    "trigger", // One or more elements that trigger the lazy load when intersected
  ];

  static values = {
    rootMargin: { type: String, default: "0px" }, // The rootMargin value to set on the IntersectionObserver
    threshold: { type: Number, default: 0.2 }, // The threshold value to set on the IntersectionObserver
    src: String, // The src value to set on the controller element when the trigger is intersected
    category: Object, // Category data for Google Analytics
  };

  initialize() {
    const options = {
      root: document,
      rootMargin: this.rootMarginValue,
      threshold: this.thresholdValue,
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.element.addEventListener("turbo:frame-load", this.onTurboFrameLoad);
  }

  disconnect() {
    if (!this.observer) {
      return;
    }

    this.observer.disconnect();
  }

  handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      this.element.src = this.srcValue;
      this.observer.unobserve(entry.target);
      this.observer.disconnect();
    });
  };

  triggerTargetConnected(trigger) {
    this.observer.observe(trigger);
  }

  triggerTargetDisconnected(trigger) {
    this.observer.unobserve(trigger);
  }

  // Remove the turbo-frame element from the DOM after it has loaded
  // and replace it with its child elements for easier styling
  onTurboFrameLoad = (event) => {
    const frame = event.target;
    const items = Array.from(frame.querySelectorAll("[data-google-analytics-item-value]")).map((el) =>
      JSON.parse(el.dataset.googleAnalyticsItemValue)
    );

    if (items.length > 0 && this.hasCategoryValue) {
      googleAnalytics.viewItemList({
        category: this.categoryValue,
        items,
      });
    }

    this.element.replaceWith(...this.element.childNodes);
  };
}
