import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    buffer: { type: Number, default: 20 }
  }

  connect() {
    this.handleScroll()
  }

  handleScroll() {
    if (window.location.hash) {
      const targetElement = document.querySelector(window.location.hash)
      if (targetElement) {
        setTimeout(() => {
          const headerHeight = document.querySelector('header')?.offsetHeight || 0
          const elementPosition = targetElement.getBoundingClientRect().top
          const offsetPosition = elementPosition + window.pageYOffset - headerHeight - this.bufferValue

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          })
        }, 100)
      }
    }
  }
}
