import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { getMetaValue } from "../helpers";

export default class extends Controller {
  connect() {
    this.sortables = [];
    this.initializeSortable(this.element);

    this.element.querySelectorAll('.nested-categories').forEach((nestedList) => {
      this.initializeSortable(nestedList);
    });
  }

  disconnect() {
    this.sortables.forEach(sortable => sortable.destroy());
    this.sortables = [];
  }

  initializeSortable(element) {
    const sortable = Sortable.create(element, {
      group: 'nested',
      handle: '.cursor-grab',
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      onEnd: this.end.bind(this)
    });
    this.sortables.push(sortable);
  }

  end(event) {
    if (this.element.querySelector('.nested-categories')) {
      this.handleNestedCategories(event);
    } else {
      this.handleSimpleSort(event);
    }
  }

  handleNestedCategories(event) {
    let items = Array.from(this.element.querySelectorAll('.category-item'));
    let order = [];

    let groupedItems = items.reduce((acc, item) => {
      let parentElement = item.closest('.nested-categories') || this.element;
      let parentId = parentElement.closest('.category-item')?.dataset.sortableId || null;
      if (!acc[parentId]) {
        acc[parentId] = [];
      }
      acc[parentId].push(item);
      return acc;
    }, {});

    Object.keys(groupedItems).forEach(parentId => {
      groupedItems[parentId].forEach((item, index) => {
        order.push({
          id: item.dataset.sortableId,
          position: index + 1,
          parent_id: parentId
        });
      });
    });

    let order_data = new URLSearchParams();
    order_data.append("order", JSON.stringify(order));

    fetch('/admin/categories/reorder', {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': getMetaValue('csrf-token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: order_data,
      credentials: 'same-origin'
    });
  }

  handleSimpleSort(event) {
    let id = event.item.dataset.sortableId;
    let data = new URLSearchParams();
    data.append("sort_order", event.newIndex);

    fetch(`/admin/payment_options/${id}/reorder`, {
      method: "PATCH",
      headers: {
        'X-CSRF-Token': getMetaValue("csrf-token"),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data,
      credentials: 'same-origin'
    });
  }
}
