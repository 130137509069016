import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

export default class extends Controller {
  ALT_INPUT_CLASS = "alt-input";
  DISPLAY_OPTIONS = ["timeOnly", "dateOnly", "monthOnly", "dateTime"];

  static values = {
    display: { type: String, default: "dateTime" },
  };

  connect() {
    if (!this.DISPLAY_OPTIONS.includes(this.displayValue)) {
      throw new Error(`Invalid display value: ${this.displayValue}`);
    }

    this.flatpickr = this.initializeFlatpickr();
  }

  disconnect() {
    this.flatpickr.destroy();
  }

  initializeFlatpickr = () => {
    switch (this.displayValue) {
      case "timeOnly":
        return this.initializeTimeOnly();
      case "dateOnly":
        return this.initializeDateOnly();
      case "monthOnly":
        return this.initializeMonthOnly();
      default:
        return this.initializeDateTime();
    }
  };

  initializeDateTime = () => {
    return flatpickr(this.element, {
      enableTime: true,
      altInput: true,
      altFormat: "M d, Y h:i K",
      altInputClass: this.ALT_INPUT_CLASS,
    });
  };

  initializeTimeOnly = () => {
    return flatpickr(this.element, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "h:i K",
      altInputClass: this.ALT_INPUT_CLASS,
    });
  };

  initializeDateOnly = () => {
    return flatpickr(this.element, {
      enableTime: false,
      altInput: true,
      dateFormat: "Y-m-d",
      altFormat: "m/d/Y",
      altInputClass: this.ALT_INPUT_CLASS,
    });
  };

  initializeMonthOnly = () => {
    return flatpickr(this.element, {
      plugins: [new monthSelectPlugin({ shorthand: false, dateFormat: "F Y" })],
      dateFormat: "F Y",
      altInputClass: this.ALT_INPUT_CLASS,
    });
  };
}
